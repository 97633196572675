.breadcrumbWrapper {
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 10px;

  .breadcrumbChild {
    flex: 1 1 auto;
  }
}
