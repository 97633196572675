@import "config/includes";

.wrapper {
  display: flex;
  position: relative;
  align-items: center;

  &:hover .iconWrapper {
    display: block;
  }
}

.wrappedComponent {
  flex: 1 1 auto;
  min-width: 0;
}

.iconWrapper {
  display: none;
  background-color: $white;
  position: absolute;
  right: 0;
  padding-left: 1px; // this is to safely overlay the underlying network icon in authentication flow sidebar
  padding-right: 1px;
  @include pointer;
}

.removeIcon {
  position: relative;
  font-size: 24px;
  color: $grey500;
}
