@import "../config/includes";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  .header {
    flex: 0 0 auto;
    margin-bottom: 20px;
  }

  .body {
    flex: 1 1 auto;
    overflow: auto;

    .section {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      .sectionHeadline {
        @include fontSize13;
        color: $grey500;
        font-weight: 400;
        margin-bottom: 8px;
        margin-top: 12px;
      }
    }

    .searchList {
      list-style-type: none;

      .searchListItem {
        display: block;
        @include fontSize15;
        font-weight: 600;
        color: $textColor;
        padding: 12px 12px;

        &:hover {
          @include pointer;
          background-color: $teal100;
        }
      }
    }
  }
}
