@import "config/includes";

.tableHeader th {
  position: sticky;
  top: 0;
}

.offsetFromPageHeader {
  th {
    top: 68px;
  }
}
