@import "../config/includes";

.wrapper {
  display: flex;
  flex-direction: column;
}

.dateWrapper {
  display: flex;
}

.dynamicDate {
  flex: 1 1 auto;
  padding-right: 12px;
}

.timezone {
  padding-right: 12px;
  padding-bottom: 24px;
  width: 110px;
}

.error {
  flex: 1 1 auto;
}

.fields {
  display: flex;
}

.left {
  flex: 1 1 auto;
  padding-right: 24px;
}

.center {
  width: 500px;
  padding-right: 24px;
}

.right {
  flex: 1 1 auto;
}

.info {
  padding: 20px 0 40px 0;
  text-align: center;
}

.title {
  @include fontSize18;
  font-weight: 600;
  padding-bottom: 20px;
}

.description {
  @include fontSize15;
  color: $textColor;
}

.destinationOptions {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.scheduleTime {
  padding-top: 16px;
}
