@import "../config/includes";

.tabGroup {
  display: flex;
  align-items: center;
  flex-direction: row;
  list-style-type: none;
  padding: 0;
  margin: 0;


  &.smallDistance {
    > li { margin-right: 10px }
  }

  &.noDistance {
    > li { margin-right: 0 }
  }

  &.largeDistance {
    > li { margin-right: 40px }
  }

  > li {
    flex: 0 0 auto;
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }
}
