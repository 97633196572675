@import "config/includes";

.quickLinks {
  @include fontSize15;
}

.ul {
  list-style: none;

  li {
    margin-top: 3px;
  }

}
