@import "config/includes";

.breadcrumbWrapper {
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 8px;
  margin-top: 12px;

  .breadcrumbChild {
    flex: 1 1 auto;
  }
}

.label {
  height: 18px;
  @include fontSize13;
  color: $grey500;
}

.body {
  flex: 1 1 auto;
  overflow: auto;

  .section {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    .sectionHeadline {
      @include fontSize13;
      color: $grey500;
      font-weight: 400;
      margin-bottom: 8px;
      margin-top: 12px;
    }
  }
}
