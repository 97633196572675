@import "../config/includes";

.wrapper {
  border-radius: 8px;
  width: inherit;
  height: inherit;
  background-color: $midnightGrey;
}

.lighter {
  background-color: $grey50;
}
