@import "config/includes";

.emptyInfoScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;

  .image {
  }

  .title {
    margin-top: 26px;
    @include fontSize30;
    color: $midnight900;
    font-weight: 600;
    text-align: center;
  }

  .message {
    margin-top: 26px;
    @include fontSize15;
    color: $textColor;
    text-align: center;
    max-width: 600px;
  }

  .buttons {
    margin-top: 26px;
  }
}
