.wrapper {
  display: flex;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 24px;
}

.image {
  width: 80px;
  height: 80px;
  padding-bottom: 20px;
}
