@import "../config/includes";

.wrapper {
  padding: 20px;
  width: 276px;
}

.typeInfo {
  display: flex;
  flex-direction: column;
}

.title {
  @include fontSize13;
  font-weight: 600;
  padding-bottom: 12px;
}

.content {
  @include fontSize13;
}
