.labelWrapper {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  min-width: 0;
}

.divWithTooltip {
  min-width: 0;
  display: flex;
  flex: 1 1 auto;
}

.divWithEllipsis {
  flex: 1 1 auto;
}