@import "config/includes";

.additionalFilterButton {
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.small {
  }
}

.link {
  @include fontSize15;
  font-weight: 600;
  color: $primaryActionColor;

  &.linkActive {
    text-decoration: underline;
  }
}

.additionalFilterButtonWrapper {
  display: flex;
  align-items: center;
}

.and {
  padding-right: 12px;
  font-weight: 600;
  @include fontSize15;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
