.formGroup {
  margin: 20px 0 10px 0;
}

.spaceName {
  font-weight: 600;
}

.entitiesList {
  padding: 10px;
}
