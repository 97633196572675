@import "config/includes";

.wrapper {
  display: flex;
}

.icon {
  padding-left: 12px;
  font-size: 24px;

  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: $primaryActionColor;
  }
}
