@import "../config/includes";

.wrapper {
  padding: 20px;
  width: 276px;
}

.headline {
  display: flex;
  align-items: center;
  @include fontSize13;
  font-weight: 600;
  padding-bottom: 12px;
}

.text {
  @include fontSize13;
  margin-bottom: 12px;
}
