@import "../config/includes";

.wrapper {
  position: relative;
}

.select {
  box-sizing: border-box;
  width: 100%;
  @include pointer;

  @include noDefaultAppearance;

  background-color: $white;
  border: 1px solid $grey400;
  border-radius: 8px;
  padding: 7px 40px 7px 12px;

  color: $textColor;
  @include fontSize15;

  &:hover {
    border: 1px solid $grey600;
  }

  &:focus {
    outline: none;
    border: 1px solid $primaryActionColor;
  }

  &.disabled, &:disabled {
    color: $grey300;
    border: 1px solid $grey300;
    pointer-events: none;
  }
}

.arrow {
  position: absolute;
  top: 9px;
  right: 12px;
  pointer-events: none;

  > i {
    font-size: 24px;
  }
}

.disabledArrow {
  color: $grey300;
}

.error, .error:hover, .error:focus {
  border: 1px solid $red600;
}

.wrapper {
  &.listFilter {
    .select {
      border: 0;
      padding: 8px 40px 8px 12px;
      background-color: $midnightGrey;
      color: $grey500;
      font-weight: 600;

      // TODO: needs special styles for focus and disabled
      &:hover {
        border: 0;
        background-color: $midnight100;
      }

      &.disabled, &:disabled {
        border: 0;
        color: $grey300;
        background-color: $midnightGrey;
      }
    }

    .arrow {
      top: 8px;

      > i {
        color: $grey500;
      }
    }

    .disabledArrow {
      > i {
        color: $grey300;
      }
    }

    // TODO: set custom error style
    .error {
      background-color: $midnightGrey;
    }
      .select {
        &.active, &.open {
          background-color: $midnight100;
      }
    }
  }

  &.listFilterWhite {
    .select {
      border: 0;
      padding: 8px 40px 8px 12px;
      background-color: $white;
      color: $grey500;
      font-weight: 600;

      // TODO: needs special styles for focus and disabled
      &:hover {
        border: 0;
        background-color: $midnight100;
      }

      &.disabled, &:disabled {
        border: 0;
        color: $grey300;
        background-color: $midnightGrey;
      }
    }

    .arrow {
      top: 8px;

      > i {
        color: $grey500;
      }
    }

    .disabledArrow {
      > i {
        color: $grey300;
      }
    }

    // TODO: set custom error style
    .error {
      background-color: $midnightGrey;
    }
    .select {
      &.active, &.open {
        background-color: $midnight100;
      }
    }
  }

  &.analysis {
    .select {
      border: none;
      @include fontSize15;
      font-weight: 600;
      color: $textColor;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 24px;
      border-radius: 0;

      &:hover, &.open {
        background-color: $blue100;
      }
    }

    &:hover {
      .arrow {
        > i {
          color: $blue700;
        }
      }
    }

    .arrow {
      &.open {
        > i {
          color: $blue700;
        }
      }

      top: 12px;
      right: 24px;

      > i {
        color: $grey500;
      }
    }
  }

  &.light {
    .select {
      border: 0;
      padding: 0 26px 0 12px;
      color: $grey500;
      background-color: inherit;
      appearance: none;

      // Hide the default arrow in Internet Explorer 10 and Internet Explorer 11
      &::-ms-expand {
        display: none;
      }

      // TODO: needs special styles for focus and disabled
      &:hover, &.disabled, &:disabled {
        border: 0;
      }

      &.disabled, &:disabled {
        color: $grey300;
      }
    }

    .arrow {
      top: 0;
      right: 0;

      > i {
        color: $grey500;
      }
    }

    .disabledArrow {
      > i {
        color: $grey300;
      }
    }

    // TODO: set custom error style
    .error {

    }
  }
}
