@import "config/includes";

.form {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.input {
  flex: 1 1 auto;
  padding-right: 12px;
}

.button {
  flex: 0 0 auto;
  align-self: flex-start;
}

.nonResultPage {
  display: flex;
  flex-direction: column;
  margin: 24px 0 24px 0;
  border-top: solid 1px $grey300;
  border-bottom: solid 1px $grey300;
}
