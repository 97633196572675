@import "config/includes";

.wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    border: solid 1px $grey300;
    padding: 24px;
    margin-bottom: 24px;
    position: relative;
}

.header {
    @include fontSize18;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
}

.linkBoxHeader {
    @include fontSize15;
    font-weight: 600;
}

.infoBox {
    margin-bottom: 16px;
}

.warning {
    position: absolute;
    bottom: 25px;
    right: 24px;
    @include fontSize13;
    color: $grey500;
    font-weight: 600;
}

.errorWrapper {
    margin-top: 12px;
}
