@import "../config/includes";

.wrapper {
  height: 52px;
  color: $textColor;

  &:hover {
    background-color: $blue50;
  }

  &:hover :global(.list-hover-button-opacity) {
    opacity: 1;
  }

  &:focus {
    background-color: $blue100;
    outline: none;
  }

  &:last-child {
    border-bottom: none;
  }
}

.disabled {
  opacity: 0.5;
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.border {
  border-bottom: 1px solid $grey300;
}
