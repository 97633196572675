.wrapper {
  padding: 20px
}

.header {
  padding-bottom: 12px;
}

.parts {
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
}

.part {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-right: 12px;

  &:last-child {
    padding-right: 0;
  }
}

.examples {
  padding-bottom: 12px;
}

.example {
  padding-bottom: 6px;

  &:last-child {
    padding-bottom: 0;
  }
}

.value {
  font-weight: 800;
}

.desc {
  font-size: 12px;
}
