@import "../../../config/includes";

.wrapper {
  display: flex;
  position: relative;
  align-items: center;
}

.wrappedComponent {
  flex: 1 1 auto;
  min-width: 0;
}

.iconWrapper {
  background-color: $white;
  position: absolute;
  right: 0;
  padding-left: 1px; // this is to safely overlay the underlying network icon in authentication flow sidebar
  padding-right: 1px;
}

.stateIcon {
  position: absolute;
  font-size: 26px;
  right: 0;
  background: white;
}

.close {
  color: $red600;
}

.success {
  color: $green700;
}

.pending {
  color: $black;
}
