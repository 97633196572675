@import "config/includes";

.button {
  display: block;
  position: relative;
  width: 40px;
  height: 100%;
  margin-left: -1px; // avoid double border with text input beside
  background-color: $white;
  border: 1px solid $grey400;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  text-align: center;

  &:hover {
    border: 1px solid $grey600;
    @include pointer;
  }

  &:focus {
    outline: none;
    border: 1px solid $teal600;
  }

  &:active, &.active {
    background-color: $teal100;

    .pickerIcon {
      color: $primaryActionColor;
    }
  }

  .colorIndicator, .pickerIcon {
    position: absolute;
    left: 50%;
    top: 50%;
  }

  .colorIndicator {
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
  }

  .pickerIcon {
    margin-left: -10px;
    margin-top: -10px;
    font-size: 20px;
    color: $textColor;
  }

  &.error {
    border: 1px solid $red600;
  }
}
