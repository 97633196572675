@import "../config/includes";

.wrapper {
  width: 300px;
  padding: 20px;
  @include fontSize13;
  text-align: left;
}

.header {
  font-weight: 600;
  padding-bottom: 12px;
}

.description {
  padding-bottom: 12px;
}

.requiredPermissions {
  padding-bottom: 12px;
}

.footer {
  padding: 0 20px 20px 20px;
}

.link {
  &:hover {
    cursor: pointer;
  }
}
