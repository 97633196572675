.header {
  padding: 16px 16px 12px 16px;
  display: flex;
}

.search {
  flex: 1 1 auto;
}

.default {
  flex: 0 0 auto;
  margin: 0 0 0 8px;
}

.item {
  padding: 4px 16px;
}
