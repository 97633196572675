@import "config/includes";

.progressBarWrapper {
  overflow: hidden;
  height: 16px;
  background-color: $grey300;
  border-radius: 10px;

  .progressBar {
    float: left;
    height: 100%;
    background-color: $primaryActionColor;
  }

  .progress-bar-info {
    background-color: $primaryActionColor;
  }

  .progress-bar-success {
    background-color: $primaryActionColor;
  }

  .progress-bar-warning {
    background-color: $faceliftOrange;
  }

  .progress-bar-danger {
    background-color: $red600;
  }

  .progress-bar-green {
    background-color: $green600;
  }
}
