@import "../config/includes";

.info {
  display: flex;
}

.helpIcon {
  font-size: 16px;
  padding-left: 4px;

  &:hover {
    cursor: pointer;
    color: $primaryActionColor;
  }
}

.headerSortDown:after {
  content: ' ';
  position: relative;
  left: 10px;
  text-align: right;
  border: 6px solid transparent;
  top: 11px;
  border-top-color: silver;
}

.headerSortUp:after {
  content: ' ';
  position: relative;
  left: 10px;
  text-align: right;
  border: 6px solid transparent;
  bottom: 11px;
  border-bottom-color: silver;
}

.sortable {
  &:hover {
    cursor: pointer;
  }
}
