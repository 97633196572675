@import "config/includes";

.formFieldWrapper {
  flex: 1 1 auto;
  display: flex;

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

.aceEditor {
  display: flex;
  flex: 1 1 auto;
}

:global(.ace_editor.ace_dark.ace_autocomplete) {
  width: 630px !important;
  background: $white!important;
  color: $grey500 !important;
}

:global(.ace_dark.ace_editor.ace_autocomplete) {
  box-shadow: 2px 3px 5px rgba(0,0,0,0.2);
  border: none;
}

:global(.ace_editor.ace_dark.ace_autocomplete .ace_marker-layer .ace_active-line) {
  background-color: $midnight200;
}

:global(.ace_editor.ace_dark.ace_autocomplete .ace_marker-layer .ace_line-hover) {
  background-color: $midnight50;
}

:global(.ace_editor.ace_dark.ace_autocomplete .ace_completion-highlight) {
  color: $textColor !important;
}

:global(.ace_editor) {
  position: absolute !important;
}
