@import "config/includes";

.bar {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  margin-bottom: 16px; // as this component is to be used right above a list, it has a static margin set inside

  .left {
    flex: 1 1 auto;
  }

  .right {
    flex: 0 0 auto;
  }
}
