@import "config/includes";

.wrapper {
  max-width: 820px;
  min-width: 620px;
}

.footer {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  padding: 20px;
  border-top: solid 1px $grey300;
}

.left {
  display: flex;
  align-items: center;
  flex: 1 0 auto;

  .icon {
    color: $grey500;
    font-size: 24px;
  }

  .text {
    color: $textColor;
    @include fontSize15;
    padding-left: 12px;
  }

}

.right {
  flex: 0 0 auto;
}
