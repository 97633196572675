@import "../config/includes";

.wrapper {
  display: flex;
}

.icon {
  font-size: 24px;
}

.valid {
  color: $grey500;
}

.invalid {
  cursor: pointer;
  color: $faceliftOrange;
}
