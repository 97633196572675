@import "../config/includes";

.wrapper {
  display: flex;
  align-items: center;
}

.profileOrGroupIcon {
  font-size: 24px;
  padding-right: 6px;
}

.label {
  @include fontSize15;
  min-width: 0;
}
