@import "config/includes";

.wrapper {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.icon {
  padding: 0 2px;

  &.youtube {
    font-size: 24px;
  }

  &.customMetric {
    font-size: 20px;
  }
}
