@import "config/fontDefinition";
@import "config/includes";
@import "config/globalHtmlStyles";

html, body, :global(#root) {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

:global(.react-hot-loader-error-overlay) {
  div {
    z-index: 9999;
    top: auto !important;
    bottom: 0;
  }
}

:global(#webpack-hot-middleware-clientOverlay) {
  z-index: $modalBase !important;
}


