@import "../config/includes";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &.wrapperDisabled {
    cursor: default;
  }
}

.input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

.radio {
  border-radius: 100%;
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  border: 1px solid $grey400;
  background-color: $white;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 14px;


  .innerRadio {
    display: block;
    width: 12px;
    height: 12px;
    border: solid 3px $white;
    border-radius: 100%;
    background-color: $white;
  }

}

.text {
  margin-left: 8px;
  @include fontSize15;
  color: $textColor;
}

.input:checked ~ .radio {
  border-color: $primaryActionColor;
  .innerRadio {
    background-color: $primaryActionColor;
  }
}



.input:disabled ~ .radio {
  background-color: $white;
  border-color: $grey300;
}

.input:disabled:checked ~ .radio {
  border-color: $grey300;
  .innerRadio {
    background: $grey300;
  }
}


.input:disabled ~ .text {
  color: $grey300;
}

.input:not(:disabled):hover ~ .radio {
  border: 1px solid $grey600;
}

.input:focus ~ .radio {
  border: 1px solid $primaryActionColor;
}


.error {

  .input:checked ~ .radio {
    border-color: $red600;

    .innerRadio {
      background-color: $red600;
    }
  }

  .radio {
    border-color: $red600;
  }

  .text {
    color: $red600;
  }
}
