@import "config/includes";

.wrapper {

}

.content {
  margin-top: 20px;
  white-space: pre-line;

  &:first-child {
    margin-top: 0;
  }
}

.primaryContent {
  color: $textColor;
}

.additionalContent {
  color: $grey500;
}

.noContent {
  color: $grey400;
}
