@import "../../../config/includes";

.wrapper {
  display: flex;
  flex-direction: column;
}

.infoWrapper {
  padding-bottom: 6px;
  display: flex;
}

.info {
  flex: 1 1 auto;
  padding-right: 12px;
}

.copy {
  flex: 0 0 auto;
}

.expandButton {
  height: 32px!important;
  background-color: transparent!important;
  border-top: 1px solid $grey400;
  margin-top: 12px!important;
}

.links {
  padding-bottom: 12px;
}

.link {
  color: $yellow600;

  &:hover {
    color: $yellow700;
  }
}
