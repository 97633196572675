@import "config/includes";


.popover {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: $white;
  border: solid 1px $grey300;
  border-radius: 8px;
  box-shadow: 0 2px 3px 0 rgba(72, 72, 72, 0.25);
  overflow: hidden; /* ensures the inner content does not overlap the rounded corners .*/

  &.reminder {
    border: 1px solid $faceliftOrange;
    background-color: $yellow50;
  }

}
