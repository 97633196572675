@import "../config/includes";

.table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  th {
    padding: 12px 0;
    font-weight: 600;
    @include fontSize13;
  }
}

.firstTable {
  border-bottom: solid 1px $grey300;
  z-index: 2;
}

.secondTable {
  margin-top: -40px;

  td {
    height: 40px;
    @include fontSize13;
    border-bottom: solid 1px $grey200;
  }
}

.secondTableDiv {
  overflow-y: auto;
  z-index: 1;
  height: 274px;

  thead {
    visibility: hidden;
  }
}

.eventTypeHeader {
  text-align: left;
  width: 75%;
}

.timeHeader {
  text-align: right;
  width: 25%;
  padding-right: 10px!important;
}

.timeColumn {
  display: flex;
  padding-right: 10px;
  flex-direction: row-reverse;
}

.message {
  min-width: 0;
}

.contentWrapper {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  min-width: 0;
}

.stateIndicator {
  padding-right: 12px;
}
