@import "../config/includes";

.header {
  display: flex;
  padding: 24px 24px 0 24px;
}

.headerTitle {
  flex: 1 1 auto;
  align-items: center;
  @include fontSize18;
  font-weight: 600;
}

.info {
  display: flex;
  align-items: center;
  @include fontSize13;
  color: $grey500;
}

.icon {
  font-size: 24px;
  padding-right: 12px;
}

.body {
  display: flex;
  flex: 1 1 auto;
  padding: 8px 14px 24px 24px;
}

.loadingAndEmptyState {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
