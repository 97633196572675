.wrapper {
  display: flex;
  height: 192px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;  // this is to have same bottom space as Body component.

  margin-top: auto;

  flex: 0 0 auto;
  >img {
    width: 180px;
    height: 60px;
  }
}
