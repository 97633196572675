@import "../config/includes";

.tab {
  cursor: pointer;
  border: none;
  display: block;
  color: $grey500;
  @include fontSize15;
  font-weight: 600;
  background-color: $white;
  padding: 23px 10px 19px 10px;
  border-bottom: 4px solid transparent;
  border-radius: 0;
  outline: none;

  &.activeTab {
    color: $blue700;
    border-bottom: 4px solid $primaryActionColor;
  }

  &:hover {
    color: $blue700;
    text-decoration: none;
  }

  &.discover {
    color: $grey500;
    border: none;
    border-top: solid 1px;
    border-bottom: solid 1px;
    padding: 3px 30px;
    border-color: $grey400;

    &:hover {
      color: $blue700;
      border-color: $primaryActionColor;
    }

    &.first {
      border-left: solid 1px;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      padding-left: 29px;
    }

    &.last {
      padding-right: 29px;
      border-right: solid 1px ;
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
    }

    &.activeTab {
      color: $white;
      background-color: $primaryActionColor;
      border-color: $primaryActionColor;
      &:hover {
        color: $white;
      }
    }
  }

  &.qqlMetricTile {
    cursor: pointer;
    background: $midnightGrey;
    display: block;
    color: $grey500;
    @include fontSize13;
    font-weight: 600;
    padding: 12px 0 9px 0;
    border-radius: 0;
    outline: none;

    &.activeTab {
      color: $blue700;
      border-bottom: 4px solid $primaryActionColor;
    }

    &:hover {
      color: $blue700;
      text-decoration: none;
    }
  }
}
