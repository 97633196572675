@import "../config/includes";

.wrapper {
  position: relative;

  .icon {
    position: absolute;
    top: 7px;
    left: 12px;
    pointer-events: none;
    color: $grey500;
    font-size: 24px;
  }

  .loadingIndicator {
    position: absolute;
    top: 8px;
    right: 12px;
    pointer-events: none;
  }
}

.input {
  box-sizing: border-box;
  width: 100%;

  background-color: $white;
  border: 1px solid $grey400;
  border-radius: 8px;
  padding: 7px 12px;

  color: $textColor;
  @include fontSize15;
  @include placeholderColor($grey400);

  &:hover {
    border: 1px solid $grey600;
  }

  &:focus {
    outline: none;
    border: 1px solid $primaryActionColor;
  }

  &.disabled, &:disabled {
    color: $grey300;
    border: 1px solid $grey300;
    pointer-events: none;
  }

  &.loading {
    background-color: $grey200;
  }


  &.error {
    border: 1px solid $red600;
  }


}

.inputWithIcon {
  padding-left: 40px; // 12px left margin + 20px icon width + 8px right margin
}

.listFilter {
  .input {
    margin: 8px 16px 7px 0;
    padding: 0;
    background: none;
    border: none;
    border-bottom: 1px solid $grey400;
    border-radius: 0;
    color: $textColor;

    &:disabled, &.disabled {
      color: $grey300;
      border-bottom: 1px solid $grey300;
      pointer-events: none;

      &::placeholder {
        color: $grey300;
      }
    }
  }

  .inputWithIcon {
    padding-left: 0;
    margin-left: 44px;
    width: calc(100% - 60px) // 44 px margin left + 16 px margin right
  }

  .icon {
    top: 8px;
    border: 0;
  }

  .iconDisabled {
    color: $grey300;
  }

  &:hover, &.active {
    background-color: $midnight100;
    border-radius: 8px;
  }

  &.disabled {
    pointer-events: none;
  }
}


.profileSearch {
  .inputWithIcon {
    padding-left: 50px; // 18px left margin + 24px icon width + 8px right margin
  }

  .iconDisabled {
    color: $grey300;
  }

  &.disabled {
    pointer-events: none;
  }
}

.discover {
  box-shadow: 0 10px 20px 0 rgba(166, 166, 166, 0.2);

  .input {
    border-color: $white;
    padding: 15px 18px;

    &:hover {
      border: 1px solid $grey600;
    }

    &:focus {
      outline: none;
      border: 1px solid $primaryActionColor;
    }
  }

  .inputWithIcon {
    padding-left: 52px; // 18px left margin + 24px icon width + 10px right margin
  }
  .icon {
    top: 15px;
    left: 18px;
  }
  .iconDisabled {
    color: $grey300;
  }
  &.disabled {
    pointer-events: none;
  }
}

.dateSelector {
  .input {
    border-radius: 20px;
    cursor: pointer;
    border-color: $grey200;


    &:focus {
    }

    &:hover {
      background: $blue50;
      border-color: transparent;
    }

    &::placeholder {
      font-style: italic;
    }

    &.error {
      color: $red600;
      &:hover, &:focus {
      }
      &:hover, {
        background: $red50;
      }
    }
  }

  &.active {
    .icon {
      color: $blue700;
    }
    .input {

      border-color: transparent;
      &.error {
        background: $red100;
        color: $red600;

        &::placeholder {
          color: $red600;
        }

      }
      font-weight: 600;
      color: $blue700;
      background: $blue100;
      &::placeholder {
        font-weight: normal;
        color: $blue700;
      }
    }
  }

  &.error {
    .icon {
      color: $red600;
    }
  }

}

.inputWithResetWrapper {
  position: relative;

  .resetFilterIcon {
    display: none;
    position: absolute;
    right: 12px;
    top: 8px;
    font-size: 24px;
    color: $grey400;
    @include pointer;

    &:not(.resetFilterIconDisabled):hover {
      color: $textColor;
    }

    &.resetFilterIconDisabled {
      cursor: default;
      pointer-events: none;
      color: $grey300;
    }
  }

  .learnMoreIcon {
    display: none;
    position: absolute;
    right: 12px;
    top: 8px;
    font-size: 24px;
    color: $grey400;
    @include pointer;
  }

  .showResetFilterIcon {
    display: block;
  }

  &.removeItemActive {
    input {
      padding-right: 36px
    }

    textarea {
      padding-right: 36px
    }

    &.profileSearch {
      input {
        padding-right: 49px
      }
    }
    &.discover {
      input {
        padding-right: 49px
      }
    }

    &.dateSelector {
      input {
        padding-right: 70px
      }
    }

    &.listFilter {
      input {
        padding-right: 28px
      }
    }
  }

  &.profileSearch {
    .resetFilterIcon {
      font-size: 24px;
      top: 8px;
      right: 18px;
    }
  }
  &.discover {
    .resetFilterIcon {
      font-size: 24px;
      top: 15px;
      right: 18px;
    }
  }
  &.listFilter {
    .resetFilterIcon {
      font-size: 24px;
      top: 8px;
      right: 16px;
    }
  }

  &.dateSelector {
    .resetFilterIcon {
      display: none;
    }

      &:hover {
        &.removeItemActive {
          .resetFilterIcon {
          display: block;
          color: $blue700;
          font-size: 24px;
          top: 7px;
          right: 40px;

          &.error {
            color: $red600;
          }
        }
      }
        .learnMoreIcon {
          display: block;
          color: $blue700;

          &.error {
            color: $red600;
          }
        }

    }
    }
}
