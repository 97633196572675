@import "config/includes";

.profileInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: $textColor;

  .profilePictureWrapper {
    padding-right: 16px;
  }

  .textWrapper {
    flex: 1 1 auto;
    min-width: 0;

    .likes {
      margin-top: 4px;
      @include fontSize15;
    }

    .profileLink {
      margin-top: 8px;
      @include fontSize15;
    }
  }

  &.large {
    align-items: center;

    .profilePictureWrapper {
      padding-right: 20px;
    }

    .textWrapper {
      .likes {
        margin-top: 12px;
      }

      .profileLink {
        margin-top: 12px;
        @include fontSize13;
      }
    }
  }
}

.insightsInfo {
  margin-left: 12.5px;
  @include fontSize13;
}
