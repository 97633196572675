@import "../config/includes";

.wrapper {
  padding: 20px;
  width: 276px;
  @include fontSize13;
}

.content {
  padding-bottom: 12px;
}

.link {
  padding-bottom: 12px;

  &:hover{
    cursor: pointer;
  }
}
