@import "config/includes";

.warning  {
  padding-bottom: 16px
};

.dashboardList {
  padding: 16px 0 16px 0;
  background-color: $white;
  border-bottom: 1px solid $grey300;
}
