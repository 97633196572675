.wrapper {
  display: flex;
}

.left {

}

.right {
 margin-left: -1px;
}

.active {
  z-index: 1;
}
