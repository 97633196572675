@import "config/includes";

.title {
  padding-bottom: 24px;
  border-bottom: 1px solid $grey300;
}

.table {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
}

.column {
  @include fontSize15;
  font-weight: 400;
  text-align: center;
  height: 88px;
  border-bottom: 1px solid $grey300;
}

.columnContent {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.useCase {
  padding-top: 14px;
  display: flex;
}

.useCaseName {
  padding-right: 5px;
}

.numberOfProfiles {
  padding-top: 10px;
}

.networkType {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.networkIcon {
  font-size: 24px;
}

.helpIcon {
  cursor: pointer;
  font-size: 16px;

  &:hover {
    cursor: pointer;
    color: $primaryActionColor;
  }
}

.unavailable {
  color: $grey500;
  width: 20px;
  border-bottom: solid 2px $grey300;
  margin: auto;
}

.feedback {
  padding-top: 20px;
}
