@import "../config/includes";

.wrapper {
  display: flex;
  align-items: center;
}

.userName {
  min-width: 0;
  padding: 0 6px 0 12px;
}

.label {
  flex: 0 0 auto;
}

.disabled {
  color: $grey300;
}

.ok {
  color: $green700;
}

.warning {
  color: $faceliftOrange;
}

.error {
  color: $red600;
}
