@import "config/includes";

.wrapper {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 20px;
  @include fontSize13;
  cursor: pointer;
}

.header {
  font-weight: 600;
  padding-bottom: 10px;
}

.body {
}
