@import "../config/includes";

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.header {
  display: flex;
  border-bottom: solid 1px $grey300;
  margin-bottom: 32px;
  padding-bottom: 28px;
}

.body {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px 100px;
  overflow: auto;
  min-width: 1000px;
}

.left {
  flex: 50%;
  padding-right: 80px;
}

.right {
  flex: 50%;
}

.info {
  height: 100%;
}

.stepDefinition {
  font-weight: 600;
  padding-right: 10px;
}
