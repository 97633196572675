
.wrapper {

  .profilePictureWrapper {
    flex: 0 0 80px;
    .profilePicture {
      width: 80px;
      height: 80px;
      background-size: cover;
      border-radius: 8px;
    }
  }

  &.large {
    .profilePictureWrapper {
      flex: 0 0 120px;
      .profilePicture {
        width: 120px;
        height: 120px;
      }
    }
  }
}
