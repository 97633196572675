@import "config/includes";

.menuButton {
  display: flex;
  align-items: center;
  @include fontSize15;
  border: 0;
  outline: none;
  box-sizing: border-box;
  color: $textColor;
  padding: 12px;
  @include pointer;
  background-color: $white;

  &.active {
    background-color: $blue100;
    text-decoration: none;

    &:hover {
      background-color: $blue200;
    }

    .multiIcon, .labelIconAndIndicator .icon {
      color: $blue700;
    }
  }

  &:hover {
    background-color: $blue50;
    text-decoration: none;

    .multiIcon, .labelIconAndIndicator .icon {
      color: $blue700;
    }
  }

  .labelIconAndIndicator {
    display: flex;
    align-items: center;
    flex: 1 1 auto;

    .icon {
      font-size: 24px;
      margin-right: 10px;
      color: $grey500;
    }

    .indicator {
      flex: 0 0 auto;
      color: $blue700;
      margin-left: 8px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 4px;
      background-color: $blue700;
    }
  }

  .multiIcon {
    font-size: 24px;
    color: $grey500;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
