@import "config/includes";

.table {
  overflow: auto;
  height: 270px;
}

.column {
  background-color: white;
  padding-bottom: 24px!important;
  padding-top: 20px!important;
  box-shadow: inset 0 0 0 $grey300, inset 0px -1px 0 $grey300;
}

.firstColumn {
  width: 30%;
}

.authUser {
  border-right: 1px solid $grey300;
}

.unavailable {
  color: $grey500;
  width: 20px;
  border-bottom: solid 2px $grey300;
  margin: auto;
}

.useCase {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.field {
  padding-bottom: 12px;
}

.labelAndIconHelp {
  min-width: 0;
  display: flex;
  align-items: center;
  @include fontSize13;
}

.label {
  white-space: nowrap;
}

.helpIcon {
  padding-left: 4px;
  font-size: 16px;

  &:hover {
    cursor: pointer;
    color: $primaryActionColor;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
