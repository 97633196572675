@import "../config/includes";

.header th {
  background-color: white;
  font-weight: 600;
  border-bottom: 1px solid $grey300;

  padding-left: 0!important;
}

.row td {
  color: $textColor;
  padding-left: 0!important;
}

.expiryAndActions {
  display: flex;
}

.expiry {
  flex: 1 1 auto;
}

.actions {
  flex: 0 0 auto;
  display: flex;
}

.icon {
  font-size: 24px;

  &:hover {
    cursor: pointer;
    color: $primaryActionColor;
  }
}

.edit {
  padding-right: 16px;
}

.copy {
  padding-right: 16px;
}

.delete {

  &:hover {
    cursor: pointer;
    color: $red600;
  }
}

.disabled {
  opacity: 0.5;
}
