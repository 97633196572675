@import "config/includes";

.wrapper {
  flex: 1 1 auto;
  min-width: 1020px;
  margin: 0 auto;
  display: flex;
  height: 100%;
}

.modal {
  padding: 0!important;
}

.left {
  flex: 1 1 auto;
  padding: 40px 0 0 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.profileSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  max-width: 420px;
  min-width: 310px;
  width: 100%;

  .profileInfo {
    padding-bottom: 12px;
    width: 100%;
  }

  .groupInfo {
    display: flex;
    width: 100%;
  }

  .groupListItem {
    flex: 1 1 auto;
    min-width: 0;
    width: 0;
    justify-content: center;
  }
}

.right {
  flex: 1 1 auto;
  margin-left: 40px;
  padding-right: 40px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.profileUseCaseEventAndHealths {
  max-width: 820px;
  min-width: 620px;
  padding: 40px 0;
}

.headline {
  display: flex;
  align-items: center;
  @include fontSize18;
  font-weight: 600;
  margin: 0;
  padding-bottom: 15px;

  .icon {
    flex: 0 0 auto;
    margin-right: 8px;
    font-size: 24px;
  }

  .title {
    flex: 0 0 auto;
  }
}

.text {
  @include fontSize15;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}

.instagramWarning {
  margin-top: 20px;
}

.customization {
  width: 100%;
}

.customizationForm {
  padding: 24px 0 24px 0;
}

.spaceProfileUseCaseForm {
  border: solid 1px $grey300;
  margin-bottom: 40px;
}

.profileEvents {
  height: 400px;
}

.profileHealth {
  margin-top: 40px;
}

.unhealthy {
  border: solid 1px $red600;
}
