@import "../config/includes";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $textColor;
  height: 52px;
  background-color: $white;
  padding-right: 20px;

  &:hover {
    background-color: $blue50;
    cursor: pointer;
  }
}

.left {
  flex: 1 1 60%;
  min-width: 280px;
  display: flex;
  align-items: center;
}

.right {
  flex: 1 1 40%;
}

.checkboxWrapper {
  padding: 0 24px;
  pointer-events: none;
}

.profile {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}

.profileInfoWrapper {
  flex: 1 1 auto;
  min-width: 0;
  width: 0;   // magically works to fully shrink the div
}

.insightsIndicator {
  padding-left: 12px;
  width: 158px;
  flex: 0 0 auto;
}
