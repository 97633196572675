@import "config/includes";

.wrapper {
  display: flex;
  flex-direction: row;
}

.iconEnabled {
  cursor: pointer;
  font-size: 24px;
}

.iconDisabled {
  color: $grey300;
  font-size: 24px;
}
