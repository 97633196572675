@import "config/includes";

.listFilters {
  list-style-type: none;
  display: flex;
}

.formFieldWrapper {
  flex: 0 0 auto;
  margin-right: 16px;
}
