@import "../config/includes";

.wrapper {
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.footer {
  display: flex;
  flex: 1 1 auto;
  padding: 16px 16px 16px 24px;
  align-items: center;
  border-top: 1px solid $grey400;
}

.selectedDate {
  flex: 1 1 auto;
  padding-right: 6px;
  min-width: 0;
}

.button {
  align-self: flex-end;
  flex: 0 0 auto;
}
