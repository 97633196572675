@import "../config/includes";

.th {
  z-index: 1; // this is to overlay relative and absolutely positioned elements inside rows (e.g. for rendering the failed red bar)
  font-weight: 400;
}

.td {

}

.viewLayout {
  @include fontSize13;
  font-weight: 400;
  text-align: left;
  background-color: $midnightGrey;
  border-left: 0 solid $grey500;
}

.padded {
  padding: 0 20px;

  &:first-child, &:last-child {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.unHealthy {
  // rows with a failed indicator now get a 4px absolutely positioned element attached
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 4px;
    top: -1px;
    bottom: -1px;
    left: 0;
    background-color: $red600;
  }
}

.disabled {
  opacity: 0.5;
}

.childrenWithRightContent {
  display: flex;
  flex-direction: row;
  align-items: center;

  .children {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 50px;
  }
}
