.wrapper {
  display: flex;
  padding: 24px 24px 0 24px;
}

.checkOption {
  flex: 0 0 auto;
  padding: 10px 12px 0 0;
}

.form {
  flex: 1 1 auto;
}