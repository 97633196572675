@import "../config/includes";

.textarea {
  vertical-align: top; // this removes the bottom default reserved vertical space
  box-sizing: border-box;
  width: 100%;

  background-color: $white;
  border: 1px solid $grey400;
  border-radius: 8px;
  padding: 10px 12px;

  color: $textColor;
  @include fontSize15;
  @include placeholderColor($grey400);

  &:hover {
    border: 1px solid $grey600;
  }

  &:focus {
    outline: none;
    border: 1px solid $primaryActionColor;
  }

  &.disabled, &:disabled {
    color: $grey300;
    border: 1px solid $grey300;
  }
}

.error, .error:hover, .error:focus {
  border: 1px solid $red600;
}

.verticalResize {
  resize: vertical;
}
