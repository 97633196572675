.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 1 auto;
  min-width: 0;
}

.item {
  min-width: 38px;
  padding-right: 8px;
  text-align: left;
}

.moreItems {
  min-width: 40px;
  padding-right: 8px;
}

.test {
  text-align: center;
}