@import "../config/includes";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;

  &.wrapperDisabled {
    cursor: default;
  }
}

.input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

.checkbox {
  border-radius: 2px;
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  border: 1px solid $grey400;
  background-color: $white;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 14px;
}

.text {
  margin-left: 12px;
  @include fontSize15;
  color: $textColor;
}

.input:checked ~ .checkbox {
  border-color: $primaryActionColor;
  background-color: $primaryActionColor;
  position: relative;
  background-image: url("data:image/svg+xml,%3C%21--%20Generated%20by%20IcoMoon.io%20--%3E%0A%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2244%22%20height%3D%2232%22%20viewBox%3D%220%200%2044%2032%22%3E%0A%3Ctitle%3Echeckmark%3C%2Ftitle%3E%0A%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M14.769%2032c-0.004%200-0.009%200-0.014%200-0.676%200-1.289-0.273-1.734-0.714l-12.308-12.308c-0.44-0.446-0.712-1.059-0.712-1.735%200-1.365%201.107-2.472%202.472-2.472%200.689%200%201.312%200.282%201.76%200.736l10.536%2010.56%2025.354-25.378c0.447-0.447%201.065-0.724%201.748-0.724%201.365%200%202.472%201.107%202.472%202.472%200%200.683-0.277%201.3-0.724%201.748l-27.077%2027.077c-0.447%200.456-1.070%200.739-1.758%200.739-0.005%200-0.010%200-0.015-0z%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E%0A");

  &.warning {
    border-color: $yellow400;
    background-color: $yellow400;
  }
}

.input:indeterminate ~ .checkbox {
  border-color: $primaryActionColor;
  background-color: $primaryActionColor;
  position: relative;
  color: $white;
  background-image: url("data:image/svg+xml,%3C%21--%20Generated%20by%20IcoMoon.io%20--%3E%0A%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22768%22%20height%3D%22768%22%20viewBox%3D%220%200%20768%20768%22%3E%0A%3Ctitle%3E%3C%2Ftitle%3E%0A%3Cg%20id%3D%22icomoon-ignore%22%3E%0A%3C%2Fg%3E%0A%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M768%20456v-144c0-13.254-10.746-24-24-24h-720c-13.254%200-24%2010.746-24%2024v144c0%2013.254%2010.746%2024%2024%2024h720c13.254%200%2024-10.746%2024-24z%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E%0A");
  background-size: 10px;
}

.input:disabled ~ .checkbox {
  background-color: $white;
  border-color: $grey300;
}

.input:disabled:checked ~ .checkbox {
  border-color: $grey300;
  background-color: $grey300;
}

.input:disabled:indeterminate ~ .checkbox {
  background-color: $grey300;
  color: $grey300;
  background-image: url("data:image/svg+xml,%3C%21--%20Generated%20by%20IcoMoon.io%20--%3E%0A%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22768%22%20height%3D%22768%22%20viewBox%3D%220%200%20768%20768%22%3E%0A%3Ctitle%3E%3C%2Ftitle%3E%0A%3Cg%20id%3D%22icomoon-ignore%22%3E%0A%3C%2Fg%3E%0A%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M768%20456v-144c0-13.254-10.746-24-24-24h-720c-13.254%200-24%2010.746-24%2024v144c0%2013.254%2010.746%2024%2024%2024h720c13.254%200%2024-10.746%2024-24z%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E%0A");
  background-size: 10px;
}

.input:disabled ~ .text {
  color: $grey300;
}

.input:not(:disabled):hover ~ .checkbox {
  border: 1px solid $textColor;
}

.input:focus ~ .checkbox {
  border: 1px solid $primaryActionColor;
}


.error {
  .input:checked ~ .checkbox {
    border-color: $red600;
    background-color: $red600;
  }

  .checkbox {
    border-color: $red600;
  }

  .text {
    color: $red600;
  }
}

.hasText {
  .checkbox {
    margin-top: 2px; // this is necessary to ensure the checkbox is on same line as text
  }
}
