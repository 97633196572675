@import "../config/includes";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageWrapper {
  flex: 1 1 auto;
  display: flex;
  align-self: self-start;
}

.noImage {
  background-color: $midnightGrey;
}

.image {
  object-fit: contain;
  object-position: center;
  border-radius: 8px;
}

.control {
  padding-top: 12px;
  display: flex;
  width: 100%;
  flex-direction: row;
}

.remove {
  margin-right: 12px;
}

.input {
  display: none;
}
