@import "config/includes";

.link {
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: none;

    .text {
      text-decoration: underline;
    }
  }

  .arrow {
    flex: 0 0 auto;
    padding-right: 4px;
    font-size: 24px;
    color: $grey500;

    &:hover {
      color: $primaryActionColor;
    }
  }

  .text {
    flex: 0 0 auto;
    @include fontSize15;
    font-weight: 600;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
