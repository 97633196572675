@import "config/includes";

.groupListItem {
  display: flex;
  align-items: center;
  padding: 3px 0;
  height: 40px;

  .groupName {
    flex: 1 1 auto;
    padding: 8px 0;
  }

  .buttons {
    flex: 0 0 auto;
    padding-left: 20px;
  }
}

.listStyle {
    @include fontSize15;
    font-weight: 600;
    color: $textColor;
}

.delete {
  &:hover {
    color: $red600;
  }
}
