@import "config/includes";

.quickLink {
  @include fontSize15;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: $textColor;
  padding: 8px;
  border-radius: 20px;


  &.active {
    text-decoration: none;
    background-color: $blue100;
    color: $blue700;

    font-weight: 600;

    &:hover {
      background-color: $blue200;
    }
  }

  &:hover {
    text-decoration: none;
    background-color: $blue50;
  }
}

.icon {
  font-size: 20px;
  margin-right: 6px;
}

.label {
  @include ellipsis;
  display: flex;
  align-items: center;
}
