@import "config/includes";

.label {
  display: flex;
  align-items: center;
  background-color: $grey200;
  padding: 3px 9px;
  border-radius: 12px;
}

.state {
  flex: 0 0 auto;
  width: 8px;
  height: 8px;
  border-radius: 50%;

  &.ok {
    background-color: $green700;
  }

  &.warning {
    background-color: $faceliftOrange;
  }

  &.error {
    background-color: $red600;
  }

  &.on {
    background-color: $primaryActionColor;
  }

  &.off {
    background-color: $white;
  }

  &.disabled {
    background-color: $grey300;
  }

  &.neutral {
    background-color: $grey600;
  }

}

.text {
  flex: 1 1 auto;
  @include fontSize13;
  font-weight: 400;
  line-height: normal;
  margin-left: 4px;
}

.icon {
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
}
