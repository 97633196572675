@import "../config/includes";

.wrapper {
  display: flex;
  align-items: stretch;

  .input {
    flex: 1 1 auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .manager {
    flex: 0 0 auto;
    display: flex;

    .target {
      flex: 1 1 auto;
      display: flex;
    }
  }
}
