@import "config/includes";

.token {
  display: flex;
  color: $textColor;
  align-items: center;
  @include pointer;
  @include fontSize13;
}


.prefix {
  color: $grey400;
  margin-right: 4px;
}

.tooltipWrapper {
  flex: 1 1 auto;
  width: 0;
}

.label {
  padding: 6px 16px;
}

.subToken {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  min-height: 32px;
  border: solid 1px $grey400;

  &:hover {
    color: $blue700;
    border: 1px solid $teal600;
    background-color: $blue50;
  }

  &:active, &.active {
    background-color: $blue100;

    &:hover {
      background-color: $blue200;
    }
  }

  &.left {
    min-width: 20px;
    flex: 1 1 auto;
    font-weight: 600;
    border-radius: 8px 0 0 8px;
    &:hover, &:active, &.active {
      z-index: 1;
    }
  }
  &.right {
    border-radius: 0 8px 8px 0;
    margin-left: -1px;
    width: 32px;
    font-size: 20px;
    flex: 0 0 auto;

    box-sizing: border-box;
    justify-content: center;
  }




  &.error {

    color: $red600;
    border: 1px solid $red600;
    background-color: $white;

    // necessary to e.g. also color inner appendix span
    span {
      color: $red600;
    }

    &:hover {

      background-color: $red50;
    }

    &:active, &.active {
      background-color: $red100;
    }
  }
}
