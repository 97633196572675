@import "../config/includes";

.wrapper {
  display: flex;
}


.left {
  flex: 1 1 50%;
  width: 0;
  min-width: 200px;
  align-items: center;
  padding-right: 12px;
}

.right {
  flex: 1 1 50%;
  display: flex;
  align-items: center;
  min-width: 0;

  .authUserState {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    min-width: 0;
  }

  .state {
    width: 24px;
  }

  .buttonWrapper {
    padding-left: 10px;
    flex: 0 0 auto;
  }
}

.authUserName {
  padding-left: 12px;
  min-width: 0;
}

.header {
  padding-bottom: 4px;
  font-weight: 600;
  @include fontSize15;
}

.item {
  padding-top: 24px;
}
