@import "../config/includes";

.wrapper {
  border-radius: 12px;
  border: solid 1px $grey400;
  background-color: $white;
  text-align: left;
  height: 22px;
  display: inline-flex;
  align-items: center;
}

.name {
  @include fontSize13;
  color: $textColor;
  margin: 2px 0px 2px 8px;
  max-width: 196px;
}

.icon {
  font-size: 24px;

  &:hover {
    cursor: pointer;
    color: $blue700;
  }
}
