@import "../config/includes";

.button {
  margin-bottom: 12px;
}

.filterGroup {
  margin-bottom: 28px;
}

.filterConjunctionHeader {
  align-items: center;
  display: flex;
  margin-bottom: 12px;
  @include fontSize15;
  font-weight: 600;
}


.filterGroupHeader {
  align-items: center;
  display: flex;
  margin-bottom: 12px;
  @include fontSize15;
  font-weight: 600;
}

.icon {
  flex: 0 0 auto;
  font-size: 20px;
  margin-right: 8px;
}

.header {
  flex: 1 1 auto;
}

.radioGroup {
  display: flex;
  flex: 0 0 auto;
}

.untagged {
  font-weight: 600;
  margin: 12px 0;
}

.filterWrapper {
  margin-bottom: 16px;
}
