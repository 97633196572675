@import "../config/includes";

.footer {
  flex: 1 1 auto;
  height: 100%;
  display: flex;
}

.backButtonWrapper {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  margin-right: auto;
}

.backButton {
  flex: 1 1 auto;
  display: flex;
}

.externalLink {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
}

.actions {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  margin-left: auto;
}

.header {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  min-width: 0;
}

.name {
  flex: 1 0 auto;
  display: flex;
}

.authTransactionTimeState {
  min-width: 0;
  padding-left: 24px;
}
