@import "../config/includes";

.wrapper {
  display: flex;
  align-items: center;
}

.close {
  color: $red600;
}

.success {
  color: $green700;
}

.pending {
  color: $black;
}

.label {
  padding-left: 14px;
}
