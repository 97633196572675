@import "../../config/includes";

.wrapper {

}

.sentence {
  padding-bottom: 8px;
  @include fontSize13;
}

.bars {
  display: flex;
}

.bar {
  flex: 1 1 auto;
  padding-right: 12px;

  &:last-child {
    padding-right: 0;
  }
}

.error {
  color: $red800;
}
