@import "config/includes";

:global(.react-draggable-transparent-selection) {
  .body {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

.body {
  position: relative;/* need this to position inner content */
  padding: 26px 40px;
  z-index: 1;
  flex: 1 0 auto;
}
