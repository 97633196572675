@import "config/includes";

.wrapper {
  // We use this padding to cover the 4px blur from the link which is reaching outside of the link itself.
  // This is necessary because the modal body cuts overflow to ensure the inner shadows don't leave their context.
  padding-top: 4px;

  .link {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    box-shadow: 0 -1px 4px 0 $grey200;

    &:hover {
      text-decoration: none;
      .text {
        text-decoration: underline;
      }
    }

    @include pointer;

    .icon {
      display: block;
      flex: 0 0 auto;
      font-size: 24px;
      vertical-align: middle;
    }

    .text {
      flex: 1 1 auto;
      margin-left: 8px;
      @include fontSize15;
      font-weight: 600;
    }
  }

  &.big {
    .link {
      padding: 20px;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}
