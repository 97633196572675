@import "../../../config/includes";

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchSummaryNetworkFilterWrapper {
  flex: 0 0 auto;
}

.searchSummaryTextWrapper {
  flex: 0 0 auto;
  @include fontSize15;
  color: $grey500;
}