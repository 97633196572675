@import "config/includes";

.profiles {
  padding: 16px 0 16px 0;
  background-color: $white;
  border-bottom: 1px solid $grey300;
}

.profileList {
  list-style: none;
  font-weight: 600;
}

.confirmation {
  padding-top: 16px;
}
