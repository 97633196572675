.wrapper {
  display: flex;
  flex-direction: column;
}

.entities {
  padding: 0 0 12px 22px;
}

.description {
  padding-bottom: 12px;
}

.tags {
  padding-bottom: 12px;
}