@import "../config/includes";


.menuWrapper {
  overflow: auto;
  max-height: 310px;
  @include fontSize15;

}

.menuList {
  list-style-type: none;
}

.noMatch {
  text-align: center;
  padding: 16px;
  font-style: italic;
}
