@import "../config/includes";

.wrapper {
  display: flex;
  flex-direction: row;
}

.header {
  display: flex;
  align-items: center;
}

.scroller {
  padding-right: 12px;
}

.name {
  max-width: 300px;
}

.separator {
  padding: 0 12px;
}

.imageWrapper {
  padding-right: 20px;
}

.img {
  width: 380px;
  height: 380px;
  object-fit: contain;
  object-position: center;
  background: $grey200;
}

.detail {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.profileInfo {
  min-width: 0;
  padding-bottom: 20px;
  margin-bottom: 16px;
  border-bottom: 1px solid $grey300;
}

.profileListItemWrapper {
  height: 40px;
}

.postInfo {
  padding: 0;
}

.postContentWrapper {
  flex: 1 1 auto;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid $grey300;
  height: 193px;
  overflow: auto;
}

.kpis {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid $grey300;
}

.postTags {
  flex: 0 0 auto;
}

.link {
  @include fontSize15;
  color: $textColor;

  &:hover {
    color: $primaryActionColor;
    cursor: pointer;
    text-decoration: none;
  }
}

.icon {
  font-size: 24px;
  padding-right: 12px;
}
