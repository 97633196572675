@import "config/includes";

.wrapper {
  display: flex;
  width: 480px;
  margin: 20px 0;

  .content {
    flex: 0 0 219px;
    text-align: center;

    .text {
      text-align: center;
      color: $grey400;
      @include fontSize15;
      margin-top: 20px;
    }

    .buttons {
      margin-top: 30px;

      .button {
        margin: 0 auto;
      }
    }
  }

  .spacer {
    flex: 0 0 1px;
    background-color: $grey300;
    margin: 0 20px;
  }
}
