@import "config/includes";

.wrapper {
  .body {
    padding: 10px;

    // Wrapper needs position: relative
    .saturationWrapper {
      position: relative;
      width: 200px;
      height: 200px;
    }

    // Wrapper needs position: relative
    .hueWrapper {
      margin-top: 5px;
      position: relative;
      width: 200px;
      height: 10px;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-top: 1px solid $grey300;

    .footerLeft {
      flex: 0 0 auto;
      display: flex;
      align-items: center;

      .colorIndicator {
        flex: 0 0 auto;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        box-shadow: 0 0 5px $grey400;
        margin-left: 1px;
      }

      .hex {
        margin-left: 10px;
        flex: 0 0 auto;
      }
    }

    .footerRight {
      flex: 0 0 auto;
    }
  }
}
