@import "includes";

* {
  margin: 0;
  padding: 0;
}

html, body, textarea, button, input, select {
  font-family: 'Inter', 'Amiri', sans-serif;
  font-weight: 400;
  color: $textColor;
  @include fontSize15;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: $blue800;
  @include pointer;

  &:hover {
    text-decoration: underline;
  }
}

button {
  text-align: left;
}

ul {
  list-style-position: inside;
}

b, strong {
  font-weight: 600;
}

[class^="icon-"], [class*=" icon-"] {
  vertical-align: middle;
  line-height: 1;
}
