@import "config/includes";

.modalListItem {
  @include pointer;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.fullOpaque {
    opacity: 1;
  }

  &:hover {
    background-color: $blue50;
  }
}
