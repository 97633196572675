@import "../../../config/includes";

.wrapper {
  display: flex;
  flex-direction: column;
}

.description {
  display: flex;
  align-items: center;
  padding-bottom: 24px;
}

.audienceData {
  border-bottom: solid 1px $grey300;
  padding-bottom: 24px;
  margin-bottom: 20px;
}

.checkMark {
  font-size: 32px;
  color: $green600;
  padding-right: 12px;
}

.links {
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;
}
