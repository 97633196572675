@import "config/includes";

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $modalBase;
  background: $black;
  opacity: 0.5;
}

.modal {
  position: fixed;
  z-index: $modalBase;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalWithBackDropSupport {
  position: fixed;
  z-index: $modalBase;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  .dialog {
    max-width: 100%;
  }
}

.drawer {
  top: 0;
  left: auto;
  right: 0;
  height: 100%;
  display: flex;
  outline: 0;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  background-color: $white;
  z-index: $modalBase;
  box-shadow: -10px 0 20px 0 rgba(72, 72, 72, 0.25);
}

.drawerDialog {
  width: 280px;
  z-index: 2;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.drawerHeader {
  @include fontSize18;
    font-weight: 600;
    color: $textColor;
    flex: 0 0 auto;

  .titleWithClose {
    display: flex;
    .title {
      flex: 1 1 auto;
    }

    .close {
      @include pointer;
      flex: 0 0 auto;

      .referenceWrapper {
        display: flex;
      }

      .closeButtonIcon {
        font-size: 24px;
      }
    }
  }
}

.drawerBody {
  flex: 1 1 auto;
}

.drawerFooter {
  flex: 0 0 auto;
}

.dialog {
  max-height: 90%;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(72, 72, 72, 0.5);
  outline: 0;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.header {
  border-bottom: 1px solid $grey300;
  height: 64px;
  display: flex;
  align-items: center;
  flex: 0 0 auto;

  .content {
    flex: 1 1 auto;
    padding: 0 20px;
    @include ellipsis;

    .title {
      display: inline;
      margin: 0;
      @include fontSize18;
      font-weight: 600;
      color: $textColor;
    }
  }

  .closeButton {
    flex: 0 0 auto;
    padding: 20px;
    color: $grey500;

    &:hover {
      @include pointer;
      color: $primaryActionColor;
    }

    .closeButtonIcon {
      vertical-align: middle;
      font-size: 24px;
    }
  }
}

.footer {
  flex: 0 0 auto;
  padding: 20px;
  border-top: 1px solid $grey300;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footerLeft {
  flex: 1 0 auto;
}

.centered {
  max-width: 640px;
  margin: 0 auto;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0;
}

.body {
  position: relative;
  box-sizing: border-box;
  color: $textColor;
  @include fontSize15;
  overflow-y: auto;
  flex: 1 1 auto;
}

.padded {
  padding: 20px;
}

.stickyFooterWrapper {
  flex: 0 0 auto;
  overflow-x: hidden;
}

.fullscreen {
    &.dialog {
      width: 100%;
      height: 100%;
      max-height: 100%;
      margin: 0;
      padding: 0;
      transform: none;
      top: 0;
      left: 0;

      border-radius: 0;
      box-shadow: none;
      max-width: 100%;
    }

    .header {
      height: 68px;
      text-align: center;
      position: relative; // we use absolute positioning instead of flexbox here because otherwise we cannot guarantee the title to be perfectly centered

      .leftContent {
        position: absolute;
        top: 0;
        left: 0;
        padding: 22px;
      }

      .content {
        padding: 0 24px;

        .title {
          @include fontSize24;
        }
      }

      .closeButton {
        position: absolute;
        top: 0;
        right: 0;
        padding: 22px;
        color: $grey500;

        &:hover {
          @include pointer;
          color: $blue800;
        }

        .closeButtonIconWrapper {
          display: flex;
          align-items: center;

          .closeButtonIcon {
            vertical-align: middle;
            font-size: 24px;
          }
        }
      }
    }

    .body {
      padding: 40px;
    }
}

.spacerBig {
  @include spacerBig;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
