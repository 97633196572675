@import "../config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}

.icon {
  @include fontSize24;
  padding-right: 12px;
}

.name {
  min-width: 0;
}