@import "config/includes";

.wrapper {
  display: flex;
  align-items: center;
  line-height: normal;

  .iconWrapper {
    flex: 0 0 auto;
    padding-left: 12px;
    line-height: 1;

    .icon {
      font-size: 24px;
      color: $primaryActionColor;
    }
  }

  .textWrapper {
    flex: 1 1 auto;
    padding-left: 16px;

    .name {
      @include fontSize15;
      font-weight: 600;
      color: $textColor;
    }

    .labels {
      padding-bottom: 2px; // this is to make up the lower line-height in comparison to the headline to make the whole entry perfectly vertically centered
      display: flex;
      align-items: stretch;
      list-style-type: none;

      .labelIcon {
        margin-right: 3px;
      }

      .label {
        display: flex;
        align-items: center;
        flex: 0 0 auto;
        @include fontSize13;
        color: $grey500;

        &:not(:first-child) {
          &:before {
            content: "·";
            vertical-align: middle;
            margin: 0 5px;
          }
        }
      }
    }
  }
}
