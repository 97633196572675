@import "config/includes";

.iconButton {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;

  &:focus {
    outline: none;
  }
}

.icon {
  color: $grey500;
  font-size: 24px;

  &:hover {
    @include pointer;
    color: $primaryActionColor;
  }
}
