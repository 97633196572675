.wrapper {

}

.title {
  font-weight: 500;
  padding-bottom: 20px;
}

.text {
  padding-bottom: 12px;
}

.info {
  display: flex;
}

.link {
  flex: 1 1 auto;
}

.date {
  flex: 0 0 auto;
}