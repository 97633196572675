@import "../config/includes";

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.header {
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  border-bottom: solid 1px $grey300;
  margin-bottom: 32px;
  padding-bottom: 28px;
}

.left {
  flex: 1 1 auto;
  padding-right: 40px;
}

.right {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}

.help {
  padding-right: 12px;
}

.body {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px 100px;
  overflow: auto;
  min-width: 1000px;
}

.title {
  min-width: 0;
}

.info {
  height: 100%;
}

.stepDefinition {
  font-weight: 600;
  padding-right: 10px;
}
