@import "../config/includes";

.selectListItemWrapper {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  // If we are in scroll context, we will also keep the margin bottom to not let the scroll area
  // end with no extra space. But if there is no scroll context, show no extra space.
  &.lastItemInNoScrollContext {
    margin-bottom: 0;
  }

    .selectWithInfoIcon {
      display: flex;
      flex-direction: row;

      .iconWrapper {
        flex: 0 0 auto;
        padding-left: 12px;
        line-height: 1;

        .icon {
          font-size: 24px;
          color: $grey500;
          @include pointer;
        }
      }
    }

    .optionInnerContent {
      margin: 8px 8px 0 32px;
    }

    .warning {
      padding: 20px 0 24px 0;
    }
}
