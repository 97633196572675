@import "config/includes";

.dateSelector {
  padding: 16px;
  color: $textColor;
}

.body {
  overflow: auto;
  width: 843px;
}

.left {
  float: left;
  width: 576px;
}

.right {
  float: left;
  width: 250px;
  margin-left: 16px;
}

.calendarFrom {
  float: left;
}

.calendarTo {
  float: left;
  margin-left: 16px;
}

.footer {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid $grey200;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.footerLeft {
  flex: 0 0 auto;
  min-width: 100px;
}

.footerMiddle {
  flex: 1 1 auto;
  margin: 0 32px;
}

.footerRight {
  flex: 0 0 auto;
}

:global {
  @import "react-datetime/css/react-datetime";

  .rdtPicker {
    width: 280px;
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;

    table {
      border-spacing: 0;
      border-collapse: collapse;
    }

    button {
      color: $textColor;

      &:hover {
        background-color: $blue50;
      }
    }

    .dow {
      width: 40px;
      height: 25px;
      text-align: center;
      vertical-align: middle;
      @include fontSize13;
      font-weight: 400;
      color: $grey500;
      padding-top: 16px;

      &:hover {
        cursor: default;
        background-color: inherit;
        border-radius: inherit;
      }
    }

    thead {
      tr {
        &:first-child th:hover {
          background-color: $blue50;
        }

        th {
          width: 40px;
          height: 25px;
          text-align: center;
          vertical-align: middle;
          @include fontSize15;
          font-weight: 600;
          border: none;

          &.rdtNext, &.rdtPrev {
            span {
              margin-top: -1px;
              font-size: 21px;
            }
          }

          &:hover {
            cursor: pointer;
            background-color: $blue50;
          }
        }
      }
    }

    td {
      width: 38px;
      height: 38px;
      border: 1px solid $grey200;
      text-align: center;
      vertical-align: middle;
      @include fontSize15;

      &.rdtDay:hover, &.rdtMonth:hover, &.rdtYear:hover {
        cursor: pointer;
        background-color: $blue50;
      }

      &.rdtToday {
        &:before {
          border: none;
          border-bottom: 2px solid $primaryActionColor;
          width: 20px;
          right: 9px;
          bottom: 6px;
        }
      }

      &.rdtActive {
        background-color: $blue100;
        color: $textColor;
        text-shadow: none;

        &.rdtOld, &.rdtNew {
          color: $textColor;
        }

        &:hover {
          background-color: $blue200;
          color: $textColor;
          text-shadow: none;
        }
      }

      &.rdtOld, &.rdtNew {
        color: $grey500
      }
    }
  }
}
