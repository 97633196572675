@import "../config/includes";

.wrapper {
  display: flex;
  flex-direction: column;
}

.expressionWrapper {
  flex: 1 1 auto;
}

.expression {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  position: relative;
  padding-right: 12px;
}

.translation {
  padding-top: 8px;
  font-size: 12px;
  position: absolute;
  right: 24px;
  color: $grey400;
  pointer-events: none;
}

.title {
  position: absolute;
  left: 0;
  top: -24px;
}

.help {
  position: absolute;
  right: 12px;
  top: -32px;
}

.timezone {
  align-self: flex-end;
  width: 110px;
}

.header {
  margin-bottom: 8px;
  color: $textColor;
  @include fontSize15;
  font-weight: 600;
}

.top {
  display: flex;
  padding-bottom: 6px;
}
