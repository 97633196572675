@import "../config/includes";

.wrapper {
  display: flex;
  align-items: center;
  min-width: 0;
}

.name {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  @include fontSize18;
  font-weight: 600;
  min-width: 0;

   .appendix, .iconWrapper, .privateLevelStatisticsWrapper {
    flex: 0 0 auto;
    margin-left: 8px;
    line-height: 1;
  }

  .profileName {
    flex: 0 1 auto;
    min-width: 0;
    margin-left: 8px;
  }

  .verifiedIcon, .globalPageIcon, .communityIcon, .showcaseIcon .instagramPersonalAccountIcon {
    font-size: 24px;
    color: $grey500;
    vertical-align: middle;
  }

  .instagramPersonalAccountIcon {
    position: relative;
    top: -2px;
  }

  .verifiedIcon {
    color: $facebookVerifiedColor;
  }

  .appendix {
    @include fontSize13;
    color: $grey400;
  }
}

.large {
  @include fontSize24;
}

.networkIconWrapper {
  flex: 0 0 auto;

  .networkIcon {
    font-size: 24px;
    vertical-align: middle;
  }
}

.platformProfileInfoButtons {
  padding-left: 8px;
}
