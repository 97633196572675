@import "../config/includes";

.wrapper {
  position: relative;
}

.info {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nameAndMetaInfo {
  padding: 24px 0 12px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.likesAndDate {
  display: flex;
  align-items: center;
  text-align: center;
  color: $grey400;
  @include fontSize13;
}

.buttons {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
}

.warningIndicator {
  padding-right: 10px;
}
