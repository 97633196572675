@import "config/includes";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .labelBar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;

    .label, .usage {
      @include fontSize13;
      font-weight: 600;
      color: $textColor;
    }
  }

  .progressBar {
    flex: 1 1 auto;
  }
}
