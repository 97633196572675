@import "../config/includes";

.header {
  display: flex;
  align-items: center;
  padding-left: 20px;
  @include fontSize18;
  margin: 0;
  @include fontSize18;
  font-weight: 600;
  color: $textColor;
}

.success {
  color: $green700;
  font-size: 24px;
  padding-right: 12px;
}

.body {
  height: 100px;
}

.footer {
  height: 40px;
}
