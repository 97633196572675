@import "config/includes";

.wrapper {
  list-style-type: none;
  padding: 0;
}

.ownAndSharedDashboards {
  padding-bottom: 24px;
}

.name {
  @include fontSize15;
  color: $textColor;
}

.icon {
  font-size: 24px;
  padding-right: 12px;
}
