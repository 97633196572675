@import "config/includes";

.wrapper {
  display: flex;
  align-items: center;
  color: $grey500;
  font-size: 20px;
  padding: 0 2px;
  text-align: center;

  &:hover, &.active {
    cursor: pointer;
    background-color: $midnight100;
    border-radius: 8px;
  }

  .foldedIcon {
    flex: 0 0 auto;
  }

  &.small {
    @include fontSize15;
  }
}
