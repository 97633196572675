@import "../config/includes";

.wrapper {
  display: flex;
  align-items: center;
  min-width: 0;
}

.isPending {
  color: $textColor;
}

.isPendingUnfulfilled {
  color: $primaryActionColor;
}

.isFulfilled {
  color: $green700;
}

.isUnfulfilled {
  color: $red600;
}

.label {
  padding-left: 14px;
}
