@import "../config/includes";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;

  &.wrapperDisabled {
    cursor: default;
  }
}

.input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

.checkbox {
  flex: 0 0 auto;
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 20px;
  border-radius: 10px;
  background-color: $grey400;
  -webkit-transition: .4s;
  transition: .4s;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-repeat: no-repeat;
  background-position: center center;
}

.checkbox:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 1px;
  top: 1px;
  background-color: $white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 10px;
}

.input:checked + .checkbox:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.input:checked ~ .checkbox {
  background-color: $primaryActionColor;
}

.input:disabled ~ .checkbox {
  background-color: $grey300;
}

.input:disabled:checked ~ .checkbox {
  border-color: $grey300;
  background-color: $grey300;
}

.input:disabled ~ .text {
  color: $grey300;
}

.text {
  margin-left: 12px;
  @include fontSize15;
  color: $textColor;
}

.error {
  .input:checked ~ .checkbox {
    border-color: $red600;
    background-color: $red600;
  }

  .checkbox {
    border-color: $red600;
  }

  .text {
    color: $red600;
  }
}

.hasText {
  .checkbox {
    margin-top: 2px; // this is necessary to ensure the checkbox is on same line as text
  }
}
