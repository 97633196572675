@import "../../../config/includes";

.item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: $textColor;

  padding: 20px 0;

  &.first {
    padding-top: 0;
  }

  &.last {
    border-bottom: 0;
  }

  .profilePictureWrapper {
    padding-right: 16px;
  }

  .detail {
    flex: 1 1 auto;
    min-width: 0;

    .meta {
      padding-bottom: 6px;
    }
  }
}
