@import "config/includes";

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  &.profile {
    background-color: $white;
    background-image: url("../resources/profileListBg.png");
  }
}

.border {
  .listItemWrapper {
    background-color: $white;
    border-bottom: 1px solid $grey300;
    &.last {
      border-bottom: none;
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}