@import "../config/includes";

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.searchInput {
  padding: 15px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.header {
  flex: 0 0 auto;
  padding: 0px 15px;
}

.nonSelectedOptions {
  @include fontSize13;
  font-weight: 600;
  color: $grey500;
  height: 42px;
}

.body {
  flex: 1 1 auto;
}

.empty {
  padding: 0px 15px;
}

.headerAndBody {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1 1 auto;
  padding-bottom: 15px;
}

.filteredList {
  max-height: 300px;
}

.selectedItem {
  padding: 0 6px 12px 0;
}

.footer {
  display: flex;
  flex: 0 0 auto;
  border: solid 1px $grey300;
  padding: 16px;
  flex-direction: row-reverse;
}
