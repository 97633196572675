@import "../config/includes";

.wrapper {
  @include fontSize13;
  color: $grey400;
}

.large {
  @include fontSize18;
}
