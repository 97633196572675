@import "../config/includes";

.wrapper {
  position: relative;
}

.error, .error:hover, .error:focus {
  border: 1px solid $red600;
}

.select {
  box-sizing: border-box;
  width: 100%;
  background-color: $white;
  border: 1px solid $grey400;
  border-radius: 8px;
  padding: 12px;

  &.disabled, &:disabled {
    color: $grey300;
    border: 1px solid $grey300;
    pointer-events: none;
  }

  option {
    @include ellipsis;
    overflow: hidden;
  }

  option:checked {
    background-color: $primaryActionColor;
    color: white;
  }
}
