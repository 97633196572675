@import "config/includes";

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.listItemWrapper {

}
