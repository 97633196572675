@import "config/includes";

.wrapper {
  .list {
    display: flex;
    align-items: center;
    list-style-type: none;
  }

  .itemWrapper {
    flex: 0 0 auto;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      &::after {
        flex: 0 0 auto;
        @include icomoon;
        content: "\e925";
        font-size: 20px;
        color: $grey500;
        margin: 0 2px;
      }
    }

    .innerItemWrapper {
      flex: 0 0 auto;
      display: flex;

      .link {
        @include fontSize18;
        color: $grey500;

        &:hover {
          color: $blue800;
        }
      }

      .lastItem {
        @include fontSize18;
      }
    }
  }

  &.small {
    .itemWrapper {
      &:not(:last-child) {
        &::after {
          @include fontSize15;
          margin: 0 2px;
        }
      }

      .innerItemWrapper {
        .link {
          @include fontSize13;
        }

        .lastItem {
          @include fontSize13;
        }
      }
    }
  }
}
