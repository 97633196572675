@import "../config/includes";

.empty {
  padding: 20px;
  text-align: center;
}

.wrapper {
  padding: 16px;
}

.textInput {
  margin-bottom: 16px;
}

.checkbox {
  margin-bottom: 16px;
}

.description {
  margin-bottom: 16px;
  font-weight: 600;
  @include fontSize13;
  color: $grey500;
}

.container {
  max-height: 75vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.header {
  height: 40px;
  padding: 16px;
  border-bottom: solid 1px $grey300;
}

.items {
  overflow-y: auto;
}
