@import "../config/includes";

.externalLink {
  color: $textColor;

  &:hover {
    text-decoration: none;
  }
}

.icon {
  font-size: 24px;

  &:hover {
    color: $primaryActionColor;
    cursor: pointer;
    text-decoration: none;
  }
}

.alertIcon {
  color: $faceliftOrange;

  &:hover {
    color: $faceliftOrange;
  }
}

.active {
  color: $primaryActionColor;
}
