@import "config/includes";

.buttons {
  display: flex;
  align-items: center;
  flex-direction: row;
  list-style-type: none;
  padding: 0;
  margin: 0;

  > li {
    flex: 0 0 auto;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    &.shrink {
      flex: 0 1 auto;
      min-width: 0;
    }
  }

  &.vertical {
    flex-direction: column;
    align-items: stretch;

    > li {
      margin-right: 0;
    }
  }
}
