@import "../config/includes";

.tag {
  display: inline-flex;
  padding: 3px 8px;
  border: 0;
  border-radius: 8px;
  background-color: $midnight100;
  @include fontSize13;
  color: $midnight600;
  outline: none;
  align-items: center;

  &.withClickBehaviour {
    @include pointer;
    &:hover {
      background-color: $midnight600;
      color: $midnight100;
    }
  }
}

.closeButton {
  @include pointer;
  display: block;
  font-size: 18px;
  padding: 2px 7px 4px 7px;
  margin: -3px -8px -3px 7px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;

  &:hover {
    background-color: $midnight600;
    color: $midnight100;
  }
}
