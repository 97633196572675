.dialog {
  width: inherit;
  height: inherit;
}

.aceEditor {
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  display: flex;
}