@import "../config/includes";

.bottomBorder {
  border-bottom: solid 1px $grey300;
  vertical-align: bottom;
}

.bottomPadded {
  margin-bottom: 20px;
}

.paddedHeader {
  padding-bottom: 12px!important;
}

.networkName {
  @include fontSize15;
  font-weight: 600!important;
  width: 250px;
}

.useCaseWithIndicator {
  display: block;
  height:  20px;
}

.useCase {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.requirementState {
  font-size: 20px;
}

.useCaseInfo {
  padding-bottom: 10px;
}

.useCaseInfoIcon {
  font-size: 24px;

  &:hover {
    color: $primaryActionColor;
    cursor: pointer;
  }
}

.unavailable {
  color: $grey500;
  width: 20px;
  border-bottom: solid 2px $grey300;
  margin: auto;
}

.highlightRows {
  table tbody tr {
    background-color: $blue50;
  }
}
