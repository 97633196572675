/* inter-regular - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('https://daq60meyd8ch7.cloudfront.net/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://daq60meyd8ch7.cloudfront.net/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('https://daq60meyd8ch7.cloudfront.net/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('https://daq60meyd8ch7.cloudfront.net/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
  url('https://daq60meyd8ch7.cloudfront.net/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('https://daq60meyd8ch7.cloudfront.net/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-500 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('https://daq60meyd8ch7.cloudfront.net/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://daq60meyd8ch7.cloudfront.net/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('https://daq60meyd8ch7.cloudfront.net/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('https://daq60meyd8ch7.cloudfront.net/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff') format('woff'), /* Modern Browsers */
  url('https://daq60meyd8ch7.cloudfront.net/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('https://daq60meyd8ch7.cloudfront.net/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-600 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('https://daq60meyd8ch7.cloudfront.net/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://daq60meyd8ch7.cloudfront.net/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('https://daq60meyd8ch7.cloudfront.net/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('https://daq60meyd8ch7.cloudfront.net/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.woff') format('woff'), /* Modern Browsers */
  url('https://daq60meyd8ch7.cloudfront.net/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('https://daq60meyd8ch7.cloudfront.net/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.svg#Inter') format('svg'); /* Legacy iOS */
}

/* amiri-regular - latin-ext_latin_arabic */
@font-face {
  font-family: 'Amiri';
  font-style: normal;
  font-weight: 400;
  src: url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-regular.woff') format('woff'), /* Modern Browsers */
  url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-regular.svg#Amiri') format('svg'); /* Legacy iOS */
}
/* amiri-italic - latin-ext_latin_arabic */
@font-face {
  font-family: 'Amiri';
  font-style: italic;
  font-weight: 400;
  src: url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-italic.woff') format('woff'), /* Modern Browsers */
  url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-italic.svg#Amiri') format('svg'); /* Legacy iOS */
}
/* amiri-700 - latin-ext_latin_arabic */
@font-face {
  font-family: 'Amiri';
  font-style: normal;
  font-weight: 700;
  src: url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-700.woff') format('woff'), /* Modern Browsers */
  url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-700.svg#Amiri') format('svg'); /* Legacy iOS */
}
/* amiri-700italic - latin-ext_latin_arabic */
@font-face {
  font-family: 'Amiri';
  font-style: italic;
  font-weight: 700;
  src: url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-700italic.woff') format('woff'), /* Modern Browsers */
  url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('https://daq60meyd8ch7.cloudfront.net/amiri-v24-latin-ext_latin_arabic-700italic.svg#Amiri') format('svg'); /* Legacy iOS */
}
