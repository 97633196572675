@import "../config/includes";

.textarea {
  height: 100%;
}

.outerEditor {
  // important is hacky but unfortunately requires the library to do so
  height: 100% !important;
  box-sizing: border-box !important;
  overflow: hidden !important;
  display: flex !important;
  flex-direction: column !important;
  font-family: inherit !important;
  border: 1px solid $grey400 !important;
  border-radius: 8px !important;
  font-size: 15px !important;
  align-items: stretch;

  &:hover {
    border: 1px solid $grey600 !important;
  }
}

.innerEditor {
  overflow-y: auto;
  @include fontSize15;
  font-size: 15px !important;
  flex: 1 1 auto;

  :global(.DraftEditor-root) {
    display: flex;
    flex: 1 1 auto;
    :global(.DraftEditor-editorContainer) {
      flex: 1 1 auto;
      display: flex;
      :global(.public-DraftEditor-content) {
        width: 100%;
        min-height: auto;
      }
    }
  }
}
