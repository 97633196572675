@import "../config/includes";

.wrapper {
  @include fontSize15;
  min-width: 800px;
}

.networkIcons {
  display: flex;
  align-items: center;
}

.emptyRow {
  opacity: 0.5;
}

.networkIcon {
  padding-right: 8px;
  font-size: 16px;

  &.youtube {
    font-size: 24px;
  }

  &.meta {
    width: 16px;
    height: 16px;
  }
}

.icon {
  color: $grey500;
  font-size: 24px;
}

.divider {
  border-bottom: 1px dashed $grey300;
  margin: 12px 0;
}
