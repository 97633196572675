@import "config/includes";

.wrapper {
  display: flex;
  align-items: center;

  .iconWrapper {
    flex: 0 0 auto;
    padding-left: 12px;
    line-height: 1;

    .icon {
      font-size: 24px;
      color: $grey500;
    }
  }

  .textWrapper {
    flex: 1 1 auto;
    padding-left: 16px;

    .infoWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .name {
        @include fontSize15;
        font-weight: 600;
        color: $textColor;
      }

      .label {
        margin-left: 8px;
      }
    }

    .labels {
      padding-bottom: 2px; // this is to make up the lower line-height in comparison to the headline to make the whole entry perfectly vertically centered
      display: flex;
      align-items: stretch;
      list-style-type: none;

      .labelIcon {
        margin-right: 3px;
      }

      .label {
        flex: 0 0 auto;
        @include fontSize13;
        color: $grey500;
        display: flex;
        align-items: center;

        &:not(:first-child) {
          &:before {
            content: "·";
            vertical-align: middle;
            margin: 0 5px;
          }
        }
      }
    }
  }
  .metricOverview {
    margin-left: 8px;
  }
}
