@import "../config/includes";

.body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.form table {
  width: 100%;
  table-layout: fixed;
  border-spacing: 10px;
  border-collapse: collapse;
}

.permissionNames {
  padding-top: 12px;
  flex: 1 1 auto;
}

.permissionTitle {
  font-weight: 600;
  @include fontSize15;
  padding-bottom: 8px;
}

.permissionName {
  @include fontSize15;
}

.info {
  flex: 0 0 auto;
}

.useCaseHeader {
  @include fontSize15;
}
