@import "config/includes";

.wrapper {
  display: flex;
  align-items: center;
  color: $grey500;

  &:hover {
    text-decoration: none;
    color: $primaryActionColor;

    .text {
      text-decoration: underline;
    }
  }
}

.icon {
  font-size: 24px;
  padding-right: 8px;
}
