@import "../config/includes";

.wrapper {
  display: flex;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.sidebar {
  flex: 1 1 auto;
  display: flex;
  min-width: 0;
}

.checkedProfiles {
  flex: 1 1 auto;
  display: block;
  padding: 32px 24px 0 24px;
  overflow-y: auto;
  min-width: 0;
}

.body {
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-width: 800px;
  padding: 0 64px;
}

.header {
  flex: 0 0 auto;
}

.adAccount {
  padding-bottom: 1px; // because profile list will overlap the border of ad account list
}

.profileList {
  flex: 1 0 auto;
  height: 80%;
  overflow-y: auto;
  border-top: solid 1px $grey300;
}

.twitterAnalyticsFeatureDisabled {
  padding-top: 32px;
}

.info {
  height: 100%;
}

.infoIcon {
  color: $grey500;
  font-size: 24px;
  padding-right: 12px;
}

.stepDefinition {
  font-weight: 600;
  padding-right: 10px;
}
