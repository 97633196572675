@import "config/includes";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body {
  overflow: hidden;
  -webkit-transition: max-height 0.3s linear;
  -moz-transition: max-height 0.3s linear;
  transition: max-height 0.3s linear;
}

.expander {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: $white;
}

.content {
  width: 100%;
}

.button {
  cursor: pointer;
}

.icon {
  font-size: 24px;
}

.buttonLabel {
  padding-left: 4px;
  @include fontSize15;
}
