@import "../config/includes";

.wrapper {
  display: flex;
  position: relative;
}

.sidebar {
  flex: 0 0 auto;
  display: flex;
  background-color: $white;
  box-shadow: -2px -2px 5px 0 rgba(163, 163, 163, 0.25);
  border-left: 1px solid $grey300;
}
