.tokenList {
  list-style-type: none;

  > li {
    margin-bottom: 8px;
  }
}

.wrapElements {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  > li {
    flex: 0 0 auto;
    margin-bottom: 8px;
    padding-right: 12px;

    &:last-child {
      padding-right: 0;
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.tokenList {
  list-style-type: none;


  > li {

  }
}

.buttonWrapper {
  min-width: 40px;
}