@import "../../../config/includes";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description {
  @include fontSize15;
  padding: 24px 0;
}

.buttons {
  width: 100%;
}
