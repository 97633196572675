@import "../config/includes";

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  border: solid 1px $grey300;
}

.unHealthy {
  border: solid 1px $red600;
}
