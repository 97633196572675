.description {
  padding-bottom: 20px;
}

.linkWrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.textInput {
  padding-bottom: 12px;
}

.links {
  display: flex;
  align-content: center;
}

.visitLink {
  padding-right: 12px;
}
