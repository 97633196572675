@import "../config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.accountsToBeAddedInfo {
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin-bottom: 24px;
  background-color: $white;
  justify-content: center;
  border-bottom: solid 1px $grey300;
}

.checkedItems {
  flex: 1 1 auto;
  padding: 0 24px 0 24px;
  overflow-y: auto;
}
