@import "../config/includes";

.wrapper {
  display: flex;
  align-items: center;
}

.disabled{
  pointer-events: none;
}

.name {
  @include fontSize18;
}

.editButton {
  flex: 0 0 auto;
  padding-left: 10px;
}

.disabledIcon {
  opacity: 0.5;
}
