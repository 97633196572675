@import "config/includes";

.listItem {
  @include pointer;

  &.active {
    background-color: $blue100;

    &:hover {
      background-color: $blue200;
    }
  }

  &:hover {
    background-color: $blue50;
  }
}
