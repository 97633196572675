@import "config/includes";

.link {
  display: flex;
  align-items: center;
  color: $grey500;

  &:hover {
    @include pointer;
    color: $primaryActionColor;
    text-decoration: none;

    .label {
      text-decoration: underline;
    }
  }
}

.small {
  .label {
    @include fontSize15;
  }

  .icon {
    font-size: 24px;
    padding-right: 8px;
  }

}

.big {
  .label {
    @include fontSize24;
  }

  .icon {
    font-size: 24px;
    padding-right: 8px;
  }
}
