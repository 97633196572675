@import "config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
}

.header {
  display: flex;
  align-items: center;
  padding: 24px;
}

.title {
  flex: 1 1 auto;
  @include fontSize18;
  font-weight: 600;
}

.info {
  display: flex;
  align-items: center;
  @include fontSize13;
  color: $grey500;
}

.body {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.chartView {
  flex: 1 1 auto;
  display: flex;
  overflow: hidden;
}

.lineChart {
  flex: 1 1 auto;
  padding: 0 24px 24px 24px;
  display: flex;
}
