@import "../config/includes";

.wrapper {
  display: flex;
  flex-direction: row;
  @include fontSize15;
  font-weight: 600;
  color: $textColor;

  .icon {
    padding: 0 12px;
    font-size: 24px;
    color: $grey500;
    @include pointer
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
