@import "config/includes";

.list {
  display: flex;
  flex-wrap: wrap;
}

.listItem {
  list-style-type: none;
  flex: 0 0 auto;
  margin: 0 28px 20px 0;
}

.item {
  width: 100px;
  display: block;
  border:none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  @include pointer;

  .label {
    @include fontSize13;
    color: $textColor;
  }

  .image {
    width: 100px;
    // Reserving 1px of margin that is being used by the border shown in active and hover states
    margin-top: 1px;
    margin-left: 1px;
    margin-bottom: 8px;
    border-radius: 8px;
    border: 1px solid $grey300;
    display: block;
    opacity: 0.5;
    filter: grayscale(100%);
  }

  &:hover {
    .image {
      border: 1px solid $grey400;
      opacity: 1;
      filter: none;
    }
  }

  &.selected {
    .image {
      border: 1px solid $primaryActionColor;
      opacity: 1;
      filter: none;
    }

    .label {
      color: $blue700;
    }
  }
}
