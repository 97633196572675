@import "../config/includes";

.wrapper {
  border-radius: 12px;
  border: solid 1px $grey400;
  background-color: $white;
  color: $textColor;
  height: 22px;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    border: solid 1px $primaryActionColor;
    color: $primaryActionColor;
  }

  &.disabled {
    border: solid 1px $grey300;
    pointer-events: none;
  }
}

:global(.hover-button-opacity) {
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

:global(.hover-button-opacity) {
  opacity: 0;
}

.icon {
  margin: 3px 8px;
  text-align: center;
  font-size: 18px;

  &.disabled {
    color: $grey300;
  }
}
