@import "../config/includes";

.table {
  width: 100%;
  table-layout: fixed;
  border-color: $grey500;
  border-collapse: collapse;

  thead {
    tr {
      height: 52px;
    }
  }

  tbody {
    background-color: $white;
  }
}
