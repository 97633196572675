@import "config/includes";

.link {
  display: flex;
  padding: 12px 16px;
  color: $textColor;
  align-items: center;

  &.active {
    text-decoration: none;
    background-color: $blue100;

    &:hover {
      background-color: $blue200;
    }
  }

  &:hover {
    text-decoration: none;
    background-color: $blue50;
  }

  .icon {
    flex: 0 0 auto;
    font-size: 24px;
    margin-right: 8px;
    &.group {
      color: $grey500
    }
  }



  .privateStatistic, .itemCount {
    flex: 0 0 auto;
  }

  .itemCount {
    margin: -12px -16px;
    padding: 12px 16px;
    text-align: right;
  }

  .itemCount, .label {
    @include fontSize15;
  }

  .hoverMenu {
    flex: 0 0 auto;
    padding: 0 0 0 8px;
    display: flex;
    gap: 4px;
  }

  .checkbox {
    padding-right: 16px;
  }


  .name {
    @include fontSize15;
    position: relative;
    top: 4px;

    &.withAppendix {
      top: -4px
    }
  }

  .ellipsisWrapper {
    min-width: 0;
    height: 32px;
    position: relative;
    flex: 1 1 auto;
  }

  .appendix {
    color: $grey400;
    @include fontSize13;
    position: relative;
    top: -8px;
  }

  }





