@import "../config/includes";

.defaultFormField {

  .errorAndInfoWrapper {
    display: flex;
    flex-direction: row;
    @include fontSize13;
    font-weight: 600;
  }

  .errorWrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }

  .error {
    margin-top: 6px;
    color: $red600;
  }

  .bellowRightInfo {
    margin-top: 6px;
    flex: 0 0 auto;
    align-self: flex-start;
  }

  .info {
    margin-top: 6px;
    color: $grey500;
  }

  .formGroup {
    margin-bottom: 24px;
  }

  &:last-child .formGroup {
    margin-bottom: 0;
  }
}

.aceEditorFormField {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  .error {
    flex: 0 0 auto;
  }

  .inputWrapper {
    flex: 1 1 auto;
    display: flex;
  }
}

.innerFormFieldWrapper {
  margin-top: -10px;
  padding: 0 0 24px 32px;
}

.fieldLabelWrapper {
  display: flex;
}

.fieldLabel {
  flex: 1 0 auto;
}

.fieldInfo {
  padding-left: 12px;
  font-size: 15px;
  max-width: 300px;
  min-width: 0;
  text-align: right;
}

.disabled {
  opacity: 0.5;
}
