@import "config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.error {
  padding-right: 26px;
}

.errorIcon {
  color: $faceliftOrange;
  font-size: 20px;
  cursor: pointer;
}

.button {
  margin-left: auto;
}
