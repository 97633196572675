@import "config/includes";

.item {
  display: flex;
  padding: 20px 0;

  &.first {
    padding-top: 0;
  }

  &.last {
    border-bottom: 0;
  }
}

.profileInfoWrapper {
  flex: 1 1 auto;
  min-width: 350px;
}

.buttonWrapper {
  padding-left: 10px;
  flex: 0 0 auto;
}
