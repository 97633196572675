
@import "../config/includes";

.wrapper {
  display: inline-block;
}

.button {
  display: flex;
  align-items: center;
  border-radius: 12px;

  &.active {
    background-color: $grey200;
  }
}

.clickable {
  &:hover {
    background-color: $grey200;
    cursor: pointer;
  }
}

.active {
  color: $textColor;
}

.label {
  padding: 0 8px 0 12px;
}

.nonActive {
  color: $grey300;
}

.ok {
  color: $green700;
}

.warning {
  color: $faceliftOrange;
}

.error {
  color: $red600;
}
