@import "../config/includes";

.wrapper {
  display: flex;
  flex-direction: row;
  width: 154px;
  height: 120px;
  border-radius: 8px;
  border: solid 1px $grey400;
  text-align: center;
  cursor: pointer;
  color: $textColor;

  &:hover, &.labelChecked {
    border: solid 1px $primaryActionColor;
    color: $blue700;
  }

  &.wrapperDisabled {
    cursor: default;
    border-color: $grey300;
    color: $grey300;
  }
}

.text {
  width: 150px;
  @include fontSize15;
  font-weight: 600;
  align-self: center;
}

.input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  padding: 0;
}

.checkbox {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: url('../../resources/icons/ic-unchecked-24-px.svg');
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: -25px;
  margin-top: 5px;
  margin-right: 5px;
}

.input:checked ~ .checkbox{
  background-image: url('../../resources/icons/ic-success-24-px.svg');
}

.input:disabled ~ .checkbox {
  background-image: url('../../resources/icons/ic-disabled-unchecked-24-px.svg');
  cursor: default;
}

.input:disabled:checked ~ .checkbox {
  background-image: url('../../resources/icons/ic-disabled-success-24-px.svg');
  cursor: default;
}

.error {
  .input:checked ~ .checkbox {
    border-color: $red600;
    background-color: $red600;
  }

  .checkbox {
    border-color: $red600;
  }

  .text {
    color: $red600;
  }
}
