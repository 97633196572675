@import "../../../config/includes";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
}

.title {
  @include fontSize15;
  padding-right: 10px;
}

.infoIcon {
  @include fontSize24;
  color: $grey500;

  &:hover {
    cursor: pointer;
    color: $primaryActionColor;
  }
}

.body {
  width: 100%;
  flex: 1 1 auto;
}

.emptyWrapper {
  padding-top: 40px;
  display: flex;
  align-items: center;
}

.emptyItem {
  padding-left: 12px;
  color: $grey400;
}

.externalLink {
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
