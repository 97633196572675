@import "config/includes";

.wrapper {
  display: flex;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.body {
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  align-items: center;
  overflow: auto;
}

.content {
  max-width: 800px;
  padding: 24px 64px 0 64px;
  flex: 1 1 auto;
  width: 100%;
}

.searchWrapper {
  padding-bottom: 24px;
}

.spacer {
  margin: 12px 0 20px 0;
  border: 0;
  border-top: 1px solid $grey300;
}

.spacerBig {
  margin: 24px 0;
  border: 0;
  border-top: 1px solid $grey300;
}

.networkFilterWrapper {
  flex: 1 1 auto;
  margin-bottom: 20px;
}

.additionalOptions {
  margin-top: 24px;
}

.authenticationDialog {
  margin-bottom: 24px;
}

.errorWrapper {
  margin-bottom: 24px;
}

.feedbackWrapper {
  margin-top: 12px;
}

.infoFeedbackWrapper {
  margin-bottom: 20px;
}

.failedLinks {
  padding-bottom: 24px;
}
