@import "config/includes";

.referenceWrapper {
  display: flex;
  min-width: 0;
}

.profileSelector {
  color: $textColor;
  width: 386px;
}

.ul {
  list-style-type: none;
}

.header {
  height: 40px;
  padding: 16px;
  border-bottom: solid 1px $grey300;
}


.resetFilterIcon {
  display: none;
  position: absolute;
  left: 220px;
  top: 32px;
  font-size: 10px;
  @include pointer;
}

.showResetFilterIcon {
  display: block;
}

.inner {
  height: 362px;
  overflow: auto;
  outline: 0;
}

.footer {
  border-top: solid 1px $grey300;
  padding: 16px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  gap: 12px;
}

.groupsHeadline {
  font-weight: 600;
  @include fontSize13;
  padding: 16px 16px 8px 16px;
}

.profilesHeadline {
  font-weight: 600;
  @include fontSize13;
  padding: 24px 16px 8px 16px;
}

.inactiveHeadline {
  color: $grey300;
}

.ulOnly {
  padding-top: 17px;
}

.listText {
  text-align: center;
  display: block;
  padding: 10px 25px;
  font-style: italic;
}

.selection {
  flex: 1 1 auto;
  @include fontSize13;
}
