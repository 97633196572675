@import "../../config/includes";

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.selectedProfileCount {
  padding-bottom: 24px;
}

.profileList {
  flex: 1;
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    padding-bottom: 20px;
  }
}



