@import "config/includes";

.wrapper {
  flex: 1 0 auto;
}

.input {
  height: 38px;
  border: 1px solid $grey500;
  font-weight: 400;
  color: $grey500;
  padding: 0 10px;
}

.inputBoxWithErrorOnRight {
  flex: 1 1 auto;
  display: flex;
  align-items: center;

  >.error {
    @include fontSize13;
    color: $red600;
    font-weight: 600;
    padding-left: 20px;
  }
}

.inputBoxWithErrorOnBottom {
  width: 100%;
  position: relative;

  >.error {
    @include fontSize13;
    color: $red600;
    font-weight: 600;
    position: absolute;
    top: 40px;
    left: 0;
  }
}
