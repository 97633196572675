.spaceWrapper {
  padding-bottom: 20px;
}
.checkBoxFields {
  display: flex;
  padding: 10px;
}

.checkboxField {
  width: 400px;
  flex-grow: 1;
}

.groupSearchBar {
  display: block;
  padding-left: 40px;
  padding-top: 12px;
}
