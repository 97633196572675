.wrapper {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  flex-wrap: wrap;
}

.item {
  padding: 0 6px 12px 0;
}
