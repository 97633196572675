@import "../config/includes";

.nonResultPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 24px 0;
  border-bottom: solid 1px $grey300;
}

.description {
  @include fontSize15;
  padding: 0 0 24px 0;
}

.buttons {
  width: 100%;
}
