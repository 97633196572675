@import "../config/includes";

.wrapper {
  width: 680px;
  height: 321px;
  display: flex;
  flex-direction: column;
}

.tabs {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid $grey300;
}

.content {
  flex: 1 1 auto;
  padding: 20px;
  height: 216px;
  display: flex;
  @include fontSize13;
}

.description {
  flex: 50%;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  border-right: 1px solid $grey300;
}

.title {
  font-weight: 600;
  padding-bottom: 12px;
}

.text {
  flex: 1 1 auto;
}

.button {
  width: 100%;
  align-self: center;

  a {
    &:hover{
      text-decoration: none;
    }
  }
}

.profiles {
  flex: 50%;
  padding-left: 20px;
  display: flex;
  height: 100%;
}

.network {
  flex: 50%;
  display: flex;
  flex-direction: column;
}

.instagram {
  padding-left: 20px;
}

.icon {
  font-size: 24px;
}

.profileList {
  flex: 1 1 auto;
  padding-top: 12px;
  overflow: auto;
}

.none {
  opacity: 0.5;
}
