@import "../config/includes";

.icon {
  font-size: 24px;
  color: $faceliftOrange;
}

.withPopover {
  &:hover {
    cursor: pointer;
  }
}
