@import "config/includes";

.wrapper {
  display: flex;
  flex-direction: column;
}

.text {
  @include fontSize13;
  padding-bottom: 12px;
}
