@import "../config/includes";

.wrapper {
  display: flex;
  flex-direction: column;
}

.body {
  display: flex;
  flex-direction: column;
}

.bottomBorder {
  border-bottom: solid 1px $grey300;
}

.section {
  margin-bottom: 22px;
}

.networkName {
  flex: 0 0 auto;
  padding-top: 70px;
  padding-right: 60px;
  @include fontSize24;
  font-weight: 600;
  width: 130px;
}

.authUserProfilesTable {
  flex: 1 1 auto;
}
