@import "../config/includes";

.filteredOptionItem {
  display: flex;
  @include fontSize15;
  color: $textColor;
  padding: 12px 15px;
}

.filteredListOptionName {
  min-width: 0;
  max-width: 320px;
}

.activeItem {
  background-color: $blue200;
}

.creatableOption {
  border-radius: 12px;
  border: solid 1px $grey400;
  text-align: left;
  height: 22px;
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  line-height: 18px;
  background-color: white;
  @include fontSize13;
}

.filteredOptionItem.activeItem .creatableOption {
  background-color: $blue100;
  border: solid 1px $primaryActionColor;
  color: $blue700;
}

.name {
  max-width: 250px;
  min-width: 0;
}

.createIcon {
  font-size: 16px;
  padding-right: 6px;
}

.disabled {
  opacity: 0.5;
}
