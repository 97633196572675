@import "../config/includes";

:global {

  .rbt {
    box-sizing: border-box;
    background-color: $white;
    min-height: 40px;
    height: auto;
    @include fontSize15;
    color: $textColor;
    border: 1px solid $grey400;
    border-radius: 8px;

    &:hover {
      border: 1px solid $grey600;
    }

    >.sr-only {
      display: none;
    }

    .rbt-input {
      padding: 8px 34px 8px 12px;
    }
  }

  .rbt-highlight-text{
    color: $textColor;
    background-color: transparent;
    font-weight: 600;
  }

  .rbt-input-wrapper {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;


    .rbt-input-hint-container {
      height: 24px;
      padding: 4px 10px 4px 0;
      display: flex;
      align-items: center;

      >div {
        position: absolute!important;
        z-index: 1;
        top: auto!important;
        left: auto!important;
      }
    }
  }

  .rbt-close {
    outline: none;
    margin-top: -12px;
    position: absolute;
    right: 11px;
    top: 50%;
    z-index: 1;

    &.close {
      -webkit-appearance: none;
      padding: 0;
      cursor: pointer;
      background: 0 0;
      border: 0;

      float: right;
      font-size: 24px;
      font-weight: 400;
      line-height: 1;
      color: $grey400;

      &:hover {
        color: $textColor;
      }

      >.sr-only{
        display: none;
      }
    }
  }

  .dropdown-menu {
    list-style-type: none;
    @include fontSize15;
    position: absolute;
    left: 0;
    top: 35px;
    width: auto;
    background-color: $white;
    border: solid 1px $grey300;
    border-radius: 8px;
    box-shadow: 0 2px 3px 0 rgba(72, 72, 72, 0.25);
    z-index: 2;

    > .active {
      > a, a:focus, a:hover {
        color: $black;
        background-color: $blue200;
        outline: 0;
      }
    }

    >.menuItem {
      > a {
        display: block;
        clear: both;
        padding: 11px 20px;
        color: $grey400;
        font-weight: 400;

        strong {
          color: $textColor;
          font-weight: 400;
        }
      }

      &.disabled {
        > :hover {
          background-color: $white;
          cursor: no-drop;
          color: $grey300;
        }
      }

      &:hover {
        background-color: $blue50;
        cursor: pointer;
      }
    }

    > .disabled {
      > a {
        display: block;
        clear: both;
        padding: 11px 20px;
        color: $grey400;
        font-weight: 400;

        strong {
          color: $textColor;
          font-weight: 400;
        }
      }
    }

    > .dropdown-header {
      padding: 11px;
      color: $textColor;

      &:hover {
        cursor: default;
        background: $white;
      }
    }

    > .divider {
      height: 1px;
      margin: 9px 0;
      overflow: hidden;
      background-color: $grey300;
    }
  }
}
