@import "../config/includes";

.wrapper {
  width: 300px;
  padding: 20px;
  @include fontSize13;
}

.text {
  padding-bottom: 12px;
}
