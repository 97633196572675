@import "../config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.body {
  flex: 1 1 auto;
  overflow: auto;
}

.footer {
  margin-top: 16px;
  padding: 16px;
  border-top: 1px solid $grey200;
  display: flex;
  flex-direction: row-reverse;
}
