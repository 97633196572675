.combinedFiled {
  display: flex;
  flex-direction: column;
}

.subField {
  margin-top: -8px
}

.password {
  padding-bottom: 24px;
}