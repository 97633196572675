@import "../config/includes";

.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.schedule {
  display: flex;
  margin-bottom: 6px;
}

.scheduleItem {
  flex: 1 1 auto;
  padding-right: 12px;
}

.timeWrapper {
  padding-right: 12px;
}

.time {
  display: flex;
  align-items: center;
};

.separator {
  margin: 0 6px;
  font-weight: bold;
}

.header {
  margin-bottom: 8px;
  color: $textColor;
  @include fontSize15;
  font-weight: 600;
}
